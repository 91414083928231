import React from 'react'
import { Table } from 'antd'

const DailyPatientsTable = ({ data }) => {
  const ParsedPatientsTableData = JSON.parse(data).PatientsTableData

  return (
    <Table bordered columns={ParsedPatientsTableData.ColsData} dataSource={ParsedPatientsTableData.TableData}
      pagination={false} size="small"
      // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
      style={{ maxWidth: 'calc(100vw - 24px)' }}
      scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
    />
  )
}

export default DailyPatientsTable;