import React, { useEffect, useState } from 'react'
import moment from "moment";
import { Tabs, Button, Typography, Menu, Dropdown, DatePicker, Select, Layout } from 'antd'
import {
  RiseOutlined, DollarOutlined, FundProjectionScreenOutlined, RedoOutlined,
  CreditCardOutlined, ReconciliationOutlined, TeamOutlined, DollarCircleOutlined,
  ExclamationCircleOutlined, DownOutlined, TableOutlined, BarChartOutlined,
  MinusCircleOutlined, ExperimentOutlined, VideoCameraOutlined
} from '@ant-design/icons';
import { StickyContainer, Sticky } from 'react-sticky';
import DailyOverviewChart from './DailyOverviewChart'
import DailyFinancialOverviewTable from './DailyFinancialOverviewTable'
import DailyFinancialOverviewChart from './DailyFinancialOverviewChart'
import DailyRevenueTable from "./DailyRevenueTable";
import DailyRevenueChart from "./DailyRevenueChart";
import DailyPatientsTable from "./DailyPatientsTable";
import DailyPatientsChart from './DailyPatientsChart'
import DailyCollectionsTable from './DailyCollectionsTable'
import DailyCollectionsChart from './DailyCollectionsChart'
import DailyOutstandingsTable from './DailyOutstandingsTable'
import DailyOutstandingsChart from './DailyOutstandingsChart'
import DailyDiscountsTable from './DailyDiscountsTable';
import DailyDiscountsChart from './DailyDiscountsChart';
import DailyExpensesTable from './DailyExpensesTable';
import DailyExpensesChart from './DailyExpensesChart';
import DailyLabServiceStatusTable from './DailyLabServiceStatusTable';
import DailyLabServiceStatusChart from './DailyLabServiceStatusChart';
import DailyRadioServiceStatusTable from "./DailyRadioServiceStatusTable";
import DailyRadioServiceStatusChart from "./DailyRadioServiceStatusChart";
import getXformedDailyDataString from '../utils/XformedDailyData';
import { default as FinalApp } from '../../Final/App';
import GetApiData from '../utils/GetApiData';
import LoadingSvg from '../Layout/LoadingSvg';

import NavBar from "../Components/NavBar";
import SideBar from "../Components/Sidebar";
import TopicMenu from "../Components/TopicMenu";
import './DailyDashboard.css';
const { TabPane } = Tabs;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DailyDashboard = ({ startPeriod, endPeriod, data, apiData, firstunitId, unitName, setZoomedIn, zoomedIn, unitList, apiHost,
  organizationId, pickerType, currencyInfo,
  reset }) => {
  console.log(apiData, "jjjjjjjjjjjjjjjhj")
  const [contentKey, setContentKey] = useState('overview');
  const [unitId, setChngdApiData] = useState('');
  const [apiDataReady, setApiDataReady] = useState(false);
  const [xformedData, setXformedData] = useState('');
  const [chngedUnitId, setChngedUnitId] = useState(firstunitId);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  // const topics = ["first", "sce", "third"];
  const topics = [];
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const [firstDate, setFirstDate] = useState(new Date(startPeriod));
  const [secDate, setSecDate] = useState(new Date(endPeriod));
  const [chngedpickerType, setchngedpickerType] = useState(pickerType);
  const [dropDownClicked, setdropDownClicked] = useState(false);
  // const [currencyInfo, setCurrencyInfo] = useState('INR');
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);

  };
  topics.push(<DailyOverviewChart data={xformedData != '' ? xformedData : data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />
    , <DailyFinancialOverviewTable apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyFinancialOverviewChart apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyRevenueTable data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <DailyRevenueChart data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyCollectionsTable data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyCollectionsChart data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyOutstandingsTable data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <DailyOutstandingsChart data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyDiscountsTable apiData={apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <DailyDiscountsChart apiData={apiData} currencyInfo={currencyInfo} />,
    <DailyPatientsTable data={xformedData != '' ? xformedData : data} apiData={unitId !== '' ? unitId : apiData} />,
    <DailyPatientsChart data={xformedData != '' ? xformedData : data} apiData={unitId !== '' ? unitId : apiData} />,
    <DailyExpensesTable apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <DailyExpensesChart apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />,
    <DailyLabServiceStatusTable apiData={apiData} />,
    <DailyLabServiceStatusChart apiData={apiData} />,
    <DailyRadioServiceStatusTable apiData={apiData} />,
    <DailyRadioServiceStatusChart apiData={apiData} />,
    <FinalApp _isLogin={true} _isAlreadyLogin={true} />,
    // <div></div>
  );
  // const Menu = (
  //   <TopicMenu
  //     topics={topics}
  //     selectedKey={selectedKey}
  //     changeSelectedKey={changeSelectedKey}
  //   />
  // );

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
      )}
    </Sticky>
  )


  const FinancialDropdownMenu = (
    <Menu>
      <Menu.Item key="0" icon={<DollarOutlined />}>
        Overview
        <Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('revenueOverviewTable')} >
          Table
        </Button>&nbsp;
        <Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('revenueOverviewChart')}>
          Chart
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="0" icon={<FundProjectionScreenOutlined />}>
        Revenue<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('revenueTable')} >Table</Button>&nbsp;
        <Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('revenueChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" icon={<CreditCardOutlined />}>
        Collections<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('collectionsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('collectionsChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" icon={<ExclamationCircleOutlined />}>
        Outstanding<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('outstandingsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('outstandingsChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" icon={<ReconciliationOutlined />}>
        Discounts<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('discountsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('discountsChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  );

  const PatientsDropdownMenu = () => (
    <Menu>
      <Menu.Item key="0" icon={<TeamOutlined />}>
        Patient Count<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('patientsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('patientsChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  )

  const ExpensesDropdownMenu = () => (
    <Menu>
      <Menu.Item key="0" icon={<MinusCircleOutlined />}>
        Expenses<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('expensesTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('expensesChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  )

  const ServiceStatusDropdownMenu = () => (
    <Menu>
      <Menu.Item key="0" icon={<ExperimentOutlined />}>
        Laboratory<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('labServiceStatusTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('labserviceStatusChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Item key="0" icon={<VideoCameraOutlined />}>
        Radiology<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('radioServiceStatusTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('radioserviceStatusChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  )



  // if (sideDrawerOpen) {

  //   backDrop = <Backdrop click={backDropClickHnadler} />
  // }


  return (
    <>
      <div style={{ height: '100%' }}>
        <div className="MobileResult">

          {


            <NavBar menu={<TopicMenu

              selectedKey={selectedKey}
              changeSelectedKey={changeSelectedKey}
            />} />


          }


          <Layout>
            <SideBar menu={Menu} />
            <Layout.Content className="content">
              {topics[contentIndex]}
            </Layout.Content>
          </Layout>
        </div>
        <div className="desktopView">
          <header className="toolbar">
            <nav className="toolBar_navigation">

              {
                console.log(currencyInfo, "ffffo")
              }
              {
                <>



                  <StickyContainer>
                    <Tabs defaultActiveKey="1" size="small"
                      renderTabBar={renderTabBar}
                      tabBarExtraContent={{
                        left: <Button icon={<RedoOutlined />} onClick={() => {
                          reset()
                          // window.location.reload(false)
                        }} style={{ marginRight: '24px', marginLeft: '12px', width: '73px' }} size="small">Reset</Button>,
                        right: <>
                          <Text type="secondary" style={{ marginRight: '12px' }}>
                            {


                              <><div className="parentDropdown">


                                <Select
                                  className="firstDD"
                                  size={'small'}
                                  defaultValue={unitName}
                                  onChange={(val, opt) => {
                                    setdropDownClicked(true)
                                    console.log(firstDate, secDate, "uuuuuuur")
                                    let fromDate;
                                    let toDate;
                                    if (pickerType == "yesterday") {
                                      fromDate = moment(new Date()).subtract(1, 'days')
                                      toDate = moment(new Date()).subtract(1, 'days')
                                    }
                                    else if (pickerType == "lastSevenDays") {
                                      fromDate = moment(new Date()).subtract(8, 'days')
                                      toDate = moment(new Date()).subtract(0, 'days')
                                    }
                                    else {
                                      fromDate = firstDate
                                      toDate = secDate
                                    }


                                    setChngedUnitId(val)

                                    let unitId = chngedUnitId;
                                    GetApiData.getCurrencyInfo({
                                      unitId,
                                      apiHost
                                    }).then((data) => {
                                      currencyInfo = data
                                      // setCurrencyInfo(data)
                                      console.log(data, "ccccccccccc")
                                    }).catch(e => {

                                    })
                                    let organizationId = JSON.parse(localStorage.getItem('organisationId'));
                                    GetApiData.daily({ organizationId, unitId, fromDate, toDate, apiHost })
                                      .then(data1 => {
                                        console.log(data1, "111111111111111")
                                        setXformedData(getXformedDailyDataString(data1))
                                        setChngdApiData(data1)
                                        setApiDataReady(true);

                                      }).catch(err => {
                                        console.log("eeeeeeeee", err);
                                        setApiDataReady(true);
                                      })
                                    // setUnitName(opt.children)
                                  }} placeholder="Select Unit" style={{ width: '360px' }}>
                                  {unitList.map(u => {
                                    return <Option value={u.unitId} key={u.unitId}>{u.unitName}</Option>
                                  })}
                                </Select>


                                {

                                  < RangePicker className="secondDD" size={'small'}

                                    disabled={chngedpickerType == "lastSevenDays" || pickerType == "lastSevenDays" ? true : false}


                                    defaultValue={
                                      pickerType == "lastSevenDays" ?
                                        [moment(new Date()).subtract(8, 'days'),
                                        moment(new Date()).subtract(0, 'days')]
                                        :
                                        [moment(startPeriod, "DD-MM-YYYY"), moment(endPeriod, "DD-MM-YYYY")]

                                    }


                                    // picker="month"
                                    // onChange={value => console.log(value)}
                                    style={{ width: '210px', margin: '0 0 0 8px' }}
                                    inputReadOnly
                                    disabledDate={disabledDate}
                                    onCalendarChange={(val) => {
                                      setdropDownClicked(true)
                                      const [start, end] = val
                                      // setChngedStartPeriod(start);
                                      // setChngedEndPeriod(end);
                                      if (start && end) {

                                        let fromDate = new Date(start)
                                        let toDate = new Date(end);
                                        setFirstDate(fromDate)
                                        setSecDate(toDate)
                                        GetApiData.daily({ organizationId, unitId: chngedUnitId, fromDate, toDate, apiHost })
                                          .then(data1 => {

                                            setXformedData(getXformedDailyDataString(data1))
                                            setChngdApiData(data1)
                                            setApiDataReady(true);

                                          }).catch(err => {
                                            console.log("eeeeeeeee", err)
                                            setApiDataReady(true);
                                          })

                                      }
                                      // if (start && end) setShowGetButton(true)

                                    }}

                                  />

                                }


                              </div>
                              </>

                            }


                          </Text>

                        </>
                      }}>
                      <TabPane tab={
                        <span>
                          <RiseOutlined />
          Overview
        </span>
                      } key="1">
                        {data == '' && <div>Loading...</div>}

                        {
                          !apiDataReady && dropDownClicked ?
                            <div className="svg"><LoadingSvg /></div> :
                            <DailyOverviewChart data={xformedData != '' ? xformedData : data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} />

                        }

                      </TabPane>

                      <TabPane tab={
                        <Dropdown overlay={FinancialDropdownMenu}>
                          <a className="ant-dropdown-link" onClick={e => e.preventDefault()} icon={<DollarCircleOutlined />}>
                            Financial <DownOutlined />
                          </a>
                        </Dropdown>
                      } key="2">
                        {
                          !apiDataReady && dropDownClicked ?
                            <div className="svg"><LoadingSvg /></div> :
                            contentKey == 'revenueOverviewChart' ? <DailyFinancialOverviewChart apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                              contentKey == 'revenueOverviewTable' ? <DailyFinancialOverviewTable apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                                contentKey == 'revenueTable' ? <DailyRevenueTable data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                                  contentKey == 'revenueChart' ? <DailyRevenueChart data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} /> :
                                    contentKey == 'collectionsTable' ? <DailyCollectionsTable data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} /> :
                                      contentKey == 'collectionsChart' ? <DailyCollectionsChart data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} /> :
                                        contentKey == 'outstandingsTable' ? <DailyOutstandingsTable data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                                          contentKey == 'outstandingsChart' ? <DailyOutstandingsChart data={data} apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} /> :
                                            contentKey == 'discountsTable' ? <DailyDiscountsTable apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                                              contentKey == 'discountsChart' ? <DailyDiscountsChart apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} /> : null


                        }
                      </TabPane>

                      <TabPane tab={
                        <Dropdown overlay={PatientsDropdownMenu}>
                          <a className="ant-dropdown-link" onClick={e => e.preventDefault()} icon={<DollarCircleOutlined />}>
                            Patients <DownOutlined />
                          </a>
                        </Dropdown>
                      } key="3">
                        {
                          !apiDataReady && dropDownClicked ?
                            <div className="svg"><LoadingSvg /></div> :
                            contentKey == 'patientsTable' ? <DailyPatientsTable data={xformedData != '' ? xformedData : data} apiData={unitId !== '' ? unitId : apiData} /> :
                              contentKey == 'patientsChart' ? <DailyPatientsChart data={xformedData != '' ? xformedData : data} apiData={unitId !== '' ? unitId : apiData} /> : null

                        }
                      </TabPane>

                      <TabPane tab={
                        <Dropdown overlay={ExpensesDropdownMenu}>
                          <a className="ant-dropdown-link" onClick={e => e.preventDefault()} icon={<DollarCircleOutlined />}>
                            Expenses <DownOutlined />
                          </a>
                        </Dropdown>
                      } key="4">
                        {
                          !apiDataReady && dropDownClicked ?
                            <div className="svg"><LoadingSvg /></div> :
                            contentKey == 'expensesTable' ? <DailyExpensesTable apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                              contentKey == 'expensesChart' ? <DailyExpensesChart apiData={unitId !== '' ? unitId : apiData} currencyInfo={currencyInfo} /> : null

                        }
                      </TabPane>

                      <TabPane tab={
                        <Dropdown overlay={ServiceStatusDropdownMenu}>
                          <a className="ant-dropdown-link" onClick={e => e.preventDefault()} >
                            Service Status <DownOutlined />
                          </a>
                        </Dropdown>
                      } key="5">
                        {
                          !apiDataReady && dropDownClicked ?
                            <div className="svg"><LoadingSvg /></div> :
                            contentKey == 'labServiceStatusTable' ? <DailyLabServiceStatusTable apiData={apiData} /> :
                              contentKey == 'labserviceStatusChart' ? <DailyLabServiceStatusChart apiData={apiData} /> :
                                contentKey == 'radioServiceStatusTable' ? <DailyRadioServiceStatusTable apiData={apiData} /> :
                                  contentKey == 'radioserviceStatusChart' ? <DailyRadioServiceStatusChart apiData={apiData} /> :
                                    null
                        }

                      </TabPane>
                    </Tabs >

                  </StickyContainer>


                </>

              }

              {/* </div> */}

            </nav>
          </header >

        </div>

      </div>
    </>

  )
}

export default DailyDashboard;

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
}