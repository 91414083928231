import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import GetApiData from '../utils/GetApiData';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
const MonthlyDiscountsTable = ({ apiData, currencyInfo, apiHost }) => {
  const fnDownloadReport = (pdfAction) => {
    console.log(apiHost, "hosttttttttt")
    let FromDate = apiData[0].trandate
    FromDate = apiData[0].trandate + "-01"
    let ToDate = apiData[apiData.length - 1].trandate.split("-");
    let _ToDate_var = new Date(ToDate[0], ToDate[1], 0);

    ToDate = moment(_ToDate_var).format("YYYY-MM-DD")
    let organization_id = localStorage.getItem("organisationId");
    let unit_id = apiData[0].unitId;
    GetApiData.downloadDiscountReport({
      FromDate,
      ToDate,
      organization_id,
      unit_id,
      apiHost,
      pdfAction
    })
      .then(data => {
        if (pdfAction == "view") {
          let pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>")

        }
      }).catch(err => {

      })
  }
  const getTableCols = () => {
    let Cols = []

    Cols.push({
      title: 'Head',
      dataIndex: 'head',
      key: 'head',

      width: window.innerWidth < 768 ? 90 : 120,
      // fixed: 'left',
      fixed: window.innerWidth < 768 ? '' : 'left',
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData && apiData.length > 0 && apiData.forEach(md => {
      Cols.push({
        title: moment(md.trandate).format("MMM-YYYY"),
        key: `head-${md['trandate']}`,
        dataIndex: `head-${md['trandate']}`,
        // width: 160,
        width: window.innerWidth < 768 ? 100 : 160,
        align: 'right',
        render: ((text, rec, idx) => {
          return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
            {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
            {
              currencyInfo + new Intl.NumberFormat('en-IN').format(text)
            }
          </span>)
        })
      })
    })

    Cols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      width: window.innerWidth < 768 ? 100 : 160,
      render: ((text, rec, idx) => {
        return (<span style={{ fontWeight: 'bold', fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
          {
            currencyInfo + new Intl.NumberFormat('en-IN').format(text)
          }
          {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
        </span>)
      })
    })

    return Cols
  }

  const getTableData = () => {
    let tableDataArray = []
    tableDataArray[0] = {} // discount 
    tableDataArray[1] = {} // concession 

    tableDataArray[0]['head'] = <span style={{ fontWeight: 'bold' }}>Discount</span>
    tableDataArray[1]['head'] = <span style={{ fontWeight: 'bold' }}>Concession</span>

    tableDataArray[0]['total'] = 0
    tableDataArray[1]['total'] = 0

    apiData && apiData.length > 0 && apiData.forEach(d => {
      let currDate = d.trandate
      let monthlyDisc = typeof d.discount !== 'undefined' ? d.discount : 0
      let monthlyConc = typeof d.concession !== 'undefined' ? d.concession : 0

      tableDataArray[0][`head-${currDate}`] = monthlyDisc
      tableDataArray[1][`head-${currDate}`] = monthlyConc

      tableDataArray[0]['total'] += monthlyDisc
      tableDataArray[1]['total'] += monthlyConc
    })
    return tableDataArray

  }

  return (
    <>
      <Table bordered columns={getTableCols()} dataSource={getTableData()}
        pagination={false} size="small"
        // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
        style={{ maxWidth: 'calc(100vw - 24px)' }}
        scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
      />
      {
        apiData && apiData.length > 0 &&
        <>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold'
          }} onClick={() => {
            fnDownloadReport("view")
          }}>
            <FilePdfOutlined /> View  PDF
        </div>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold', clear: "both"
          }} onClick={() => {
            fnDownloadReport("download")

          }}>
            <DownloadOutlined /> Download  PDF

        </div>
        </>
      }
    </>
  )
}

export default MonthlyDiscountsTable