import React, { useState } from 'react'
import moment from "moment";
import LoadingSvg from '../Layout/LoadingSvg';
import { Tabs, Button, Typography, Menu, Dropdown, Select, DatePicker, Layout } from 'antd'
import {
  RiseOutlined, DollarOutlined, FundProjectionScreenOutlined, RedoOutlined,
  CreditCardOutlined, TeamOutlined, ExclamationCircleOutlined, DownOutlined,
  TableOutlined, BarChartOutlined, DollarCircleOutlined, ReconciliationOutlined,
  MinusCircleOutlined, ExperimentOutlined, VideoCameraOutlined
} from '@ant-design/icons';
import { StickyContainer, Sticky } from 'react-sticky';
import MonthlyOverviewChart from './MonthlyOverviewChart';
import MonthlyFinancialOverviewTable from "./MonthlyFinancialOverviewTable";
import MonthlyFinancialOverviewChart from './MonthlyFinancialOverviewChart';
import MonthlyRevenueTable from "./MonthlyRevenueTable";
import MonthlyRevenueChart from "./MonthlyRevenueChart";
import MonthlyPatientsTable from "./MonthlyPatientsTable";
import MonthlyPatientsChart from './MonthlyPatientsChart';
import MonthlyCollectionsTable from './MonthlyCollectionsTable';
import MonthlyCollectionsChart from './MonthlyCollectionsChart';
import MonthlyOutstandingsTable from './MonthlyOutstandingsTable';
import MonthlyOutstandingsChart from './MonthlyOutstandingsChart';
import MonthlyDiscountsTable from './MonthlyDiscountsTable';
import MonthlyDiscountsChart from './MonthlyDiscountsChart';
import MonthlyExpensesTable from './MonthlyExpensesTable';
import MonthlyExpensesChart from './MonthlyExpensesChart';
import MonthlyLabServiceStatusTable from './MonthlyLabServiceStatusTable';
import MonthlyLabServiceStatusChart from './MonthlyLabServiceStatusChart';
import MonthlyRadioServiceStatusTable from './MonthlyRadioServiceStatusTable';
import MonthlyRadioServiceStatusChart from './MonthlyRadioServiceStatusChart';
import AlosChart from '../Pocs/AlosChart';
import ArpbChart from '../Pocs/ArpbChart'

// import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import './MonthlyDashBoard.css';
import NavBar from "../Components/NavBar";
import SideBar from "../Components/Sidebar";
import TopicMenu from "../Components/TopicMenu";
import GetApiData from '../utils/GetApiData';
// import RupeeIconSvg from '../RupeeIconSvg'

const { TabPane } = Tabs;
const { Text } = Typography
const { Option } = Select;
const { RangePicker } = DatePicker;
const MonthlyDashboard = ({ startPeriod, endPeriod, apiData, unitName, setZoomedIn, zoomedIn, unitList, currencyInfo, unitId,
  apiHost, organizationId, reset }) => {
  console.log(startPeriod, "iiiiiiiiiiiiiiiii", new Date(startPeriod), "jjjjjjjjjjjj")
  const [contentKey, setContentKey] = useState('overview');
  const [chngedStartPeriod, setChngedStartPeriod] = useState(new Date(startPeriod));
  const [chngedEndPeriod, setChngedEndPeriod] = useState(new Date(endPeriod));
  const [chngdApiData, setChngdApiData] = useState('');
  const [apiDataReady, setApiDataReady] = useState(true)
  const [chngedUnitId, setChngedUnitId] = useState(unitId)
  const topics = [];
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");
  const [showLoader, setShowLoader] = useState(false);
  const [dropDownClicked, setdropDownClicked] = useState(false);
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
    console.log(selectedKey, "yyyyyyyyyyyyk")
  };
  topics.push(
    <MonthlyOverviewChart apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyFinancialOverviewTable apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyFinancialOverviewChart apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyRevenueTable apiData={apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <MonthlyRevenueChart apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyCollectionsTable apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyCollectionsChart apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyOutstandingsTable apiData={apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <MonthlyOutstandingsChart apiData={apiData} currencyInfo={currencyInfo} />,
    <MonthlyDiscountsTable apiData={apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <MonthlyDiscountsChart apiData={apiData} currencyInfo={currencyInfo} />,


    <MonthlyPatientsTable apiData={apiData} />,
    <MonthlyPatientsChart apiData={apiData} />,


    <MonthlyExpensesTable apiData={apiData} currencyInfo={currencyInfo} apiHost={apiHost} />,
    <MonthlyExpensesChart apiData={apiData} currencyInfo={currencyInfo} />,

    <MonthlyLabServiceStatusTable apiData={apiData} />,
    <MonthlyLabServiceStatusChart apiData={apiData} />,
    <MonthlyRadioServiceStatusTable apiData={apiData} />,
    <MonthlyRadioServiceStatusChart apiData={apiData} />
  )

  const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
      )}
    </Sticky>
  )

  const FinancialDropdownMenu = (
    <Menu style={{ width: '320px' }}>
      <Menu.Item key="0" icon={<DollarOutlined />}>
        Overview<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('revenueOverviewTable')} >Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('revenueOverviewChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="0" icon={<FundProjectionScreenOutlined />}>
        Revenue<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('revenueTable')} >Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('revenueChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" icon={<CreditCardOutlined />}>
        Collection<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('collectionsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('collectionsChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" icon={<ExclamationCircleOutlined />}>
        Outstanding<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('outstandingsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('outstandingsChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" icon={<ReconciliationOutlined />}>
        Discounts<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('discountsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('discountsChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  );

  const PatientsDropdownMenu = () => (
    <Menu style={{ width: '320px' }}>
      <Menu.Item key="0" icon={<TeamOutlined />}>
        Patient Count<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('patientsTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('patientsChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Item key="0" icon={<TeamOutlined />}>
        {/* ALOS Report<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('alosReportTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('alosReportChart')}>Chart</Button> */}
        ALOS Report<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('alosReportChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Item key="0" icon={<TeamOutlined />}>
        ARPB Report<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('arpbReportChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  )

  const ExpensesDropdownMenu = () => (
    <Menu style={{ width: '320px' }}>
      <Menu.Item key="0" icon={<MinusCircleOutlined />}>
        Expenses<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('expensesTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('expensesChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  )

  const ServiceStatusDropdownMenu = () => (
    <Menu style={{ width: '320px' }}>
      <Menu.Item key="0" icon={<ExperimentOutlined />}>
        Laboratory<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('labServiceStatusTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('labserviceStatusChart')}>Chart</Button>
      </Menu.Item>
      <Menu.Item key="0" icon={<VideoCameraOutlined />}>
        Radiology<Button type="link" icon={<TableOutlined />} onClick={() => setContentKey('radioServiceStatusTable')}>Table</Button>&nbsp;<Button type="link" icon={<BarChartOutlined />} onClick={() => setContentKey('radioserviceStatusChart')}>Chart</Button>
      </Menu.Item>
    </Menu>
  )

  const onChange = () => {

  }
  // let lockAllowed = window.screen.lockOrientation('landscape-primary');
  // console.log(lockAllowed, "lllllllllllllllllllllld")
  return (
    <div style={{ height: '100%' }}>
      <div className="MobileResult">
        {
          selectedKey !== 19 &&
          <NavBar menu={<TopicMenu

            selectedKey={selectedKey}
            changeSelectedKey={changeSelectedKey}
          />} />
        }



        <Layout>
          <SideBar menu={Menu} />
          <Layout.Content className="content">
            {topics[contentIndex]

            }

          </Layout.Content>
        </Layout>
      </div>
      <div className="desktopView">
        <header className="toolbar">
          <nav className="toolBar_navigation">
            <StickyContainer>
              <Tabs defaultActiveKey="1" size="small"
                renderTabBar={renderTabBar}
                tabBarExtraContent={{
                  left: <Button icon={<RedoOutlined />} onClick={() => { reset() }} style={{ marginRight: '24px', marginLeft: '12px', width: '73px' }} size="small">Reset</Button>,
                  // right: <><Text type="secondary" style={{ marginRight: '12px' }}>| Monthly reports for <Text code>Unit: {unitName}</Text> from <Text code>{moment(startPeriod).format("MMM-YYYY")}</Text> to <Text code>{moment(endPeriod).format("MMM-YYYY")}</Text></Text>
                  right: <><Text type="secondary" style={{ marginRight: '12px' }}>


                    <div className="parentDropdown">
                      <Select size={'small'} defaultValue={unitName} onChange={(val, opt) => {
                        console.log(JSON.stringify(opt))
                        // setShowLoader(true);
                        setdropDownClicked(true)
                        setChngedUnitId(val);
                        console.log(chngedStartPeriod, chngedStartPeriod, "1111")
                        let startMonth = chngedStartPeriod;
                        let endMonth = chngedEndPeriod;
                        let unitId = chngedUnitId;
                        let organizationId = JSON.parse(localStorage.getItem('organisationId'));
                        setApiDataReady(false)
                        GetApiData.monthly({ organizationId, unitId, startMonth, endMonth, apiHost })
                          .then(data => {
                            // console.log(data)
                            setShowLoader(false)
                            // setXformedData(getXformedDailyDataString(data))
                            setChngdApiData(data)
                            setApiDataReady(true)

                          }).catch(e => {
                            setShowLoader(false)
                          })
                        // setUnitName(opt.children)
                      }} placeholder="Select Unit" style={{ width: '360px' }}>
                        {unitList.map(u => {
                          return <Option value={u.unitId} key={u.unitId}>{u.unitName}</Option>
                        })}
                      </Select> &nbsp;
            < RangePicker size={'small'}
                        defaultValue={[moment(startPeriod, "MMM-YYYY"), moment(endPeriod, "MMM-YYYY")]}

                        picker="month"
                        // onChange={value => console.log(value)}
                        style={{ width: '24vw' }}
                        inputReadOnly
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => {
                          setdropDownClicked(true);
                          setApiDataReady(false)
                          const [start, end] = val
                          setChngedStartPeriod(new Date(start));
                          setChngedEndPeriod(new Date(end));
                          if (start && end) {
                            let startMonth = new Date(start), endMonth = new Date(end);

                            setChngedStartPeriod(startMonth);
                            setChngedEndPeriod(endMonth);
                            console.log(chngedStartPeriod, chngedStartPeriod, "22222")
                            let organizationId = JSON.parse(localStorage.getItem('organisationId'));
                            let unitId = chngedUnitId;

                            GetApiData.monthly({ organizationId, unitId, startMonth, endMonth, apiHost })
                              .then(data => {
                                // console.log(data)
                                // setXformedData(getXformedDailyDataString(data))
                                setChngdApiData(data)
                                setApiDataReady(true)
                              })
                            console.log(chngdApiData, "jjjjjjjjjjjyyyyyyy")
                          }
                          // if (start && end) setShowGetButton(true)

                        }}
                      />
                    </div>


                  </Text>

                  </>
                }}>
                <TabPane tab={
                  <span>
                    <RiseOutlined />
          Overview
        </span>
                } key="1">
                  {

                    !apiDataReady && dropDownClicked ?
                      <div className="svg"><LoadingSvg /></div> :

                      < MonthlyOverviewChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} />
                  }


                </TabPane>

                <TabPane tab={
                  <Dropdown overlay={FinancialDropdownMenu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} icon={<DollarCircleOutlined />}>
                      Financial <DownOutlined />
                    </a>
                  </Dropdown>
                } key="2">
                  {
                    !apiDataReady && dropDownClicked ?
                      <div className="svg"><LoadingSvg /></div> :
                      contentKey == 'revenueOverviewTable' ? <MonthlyFinancialOverviewTable apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                        contentKey == 'revenueOverviewChart' ? <MonthlyFinancialOverviewChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> :
                          contentKey == 'revenueTable' ? <MonthlyRevenueTable apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                            contentKey == 'revenueChart' ? <MonthlyRevenueChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> :
                              contentKey == 'collectionsTable' ? <MonthlyCollectionsTable apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> :
                                contentKey == 'collectionsChart' ? <MonthlyCollectionsChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> :
                                  contentKey == 'outstandingsTable' ? <MonthlyOutstandingsTable apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                                    contentKey == 'outstandingsChart' ? <MonthlyOutstandingsChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> :
                                      contentKey == 'discountsTable' ? <MonthlyDiscountsTable apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                                        contentKey == 'discountsChart' ? <MonthlyDiscountsChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> :
                                          null
                  }


                </TabPane>

                <TabPane tab={
                  <Dropdown overlay={PatientsDropdownMenu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} icon={<DollarCircleOutlined />}>
                      Patients <DownOutlined />
                    </a>
                  </Dropdown>
                } key="3">
                  {
                    !apiDataReady && dropDownClicked ?
                      <div className="svg"><LoadingSvg /></div> :
                      contentKey == 'patientsTable' ? <MonthlyPatientsTable apiData={chngdApiData !== '' ? chngdApiData : apiData} /> :
                        contentKey == 'patientsChart' ? <MonthlyPatientsChart apiData={chngdApiData !== '' ? chngdApiData : apiData} /> :
                          contentKey == 'alosReportChart' ? <AlosChart /> :
                            contentKey == 'arpbReportChart' ? <ArpbChart /> :
                              // contentKey == 'alosReportTable' ? <AlosTable /> :
                              null

                  }
                </TabPane>

                <TabPane tab={
                  <Dropdown overlay={ExpensesDropdownMenu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} icon={<DollarCircleOutlined />}>
                      Expenses <DownOutlined />
                    </a>
                  </Dropdown>
                } key="4">
                  {
                    !apiDataReady && dropDownClicked ?
                      <div className="svg"><LoadingSvg /></div> :
                      contentKey == 'expensesTable' ? <MonthlyExpensesTable apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} apiHost={apiHost} /> :
                        contentKey == 'expensesChart' ? <MonthlyExpensesChart apiData={chngdApiData !== '' ? chngdApiData : apiData} currencyInfo={currencyInfo} /> : null

                  }
                </TabPane>

                <TabPane tab={
                  <Dropdown overlay={ServiceStatusDropdownMenu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} >
                      Service Status <DownOutlined />
                    </a>
                  </Dropdown>
                } key="5">
                  {
                    !apiDataReady && dropDownClicked ?
                      <div className="svg"><LoadingSvg /></div> :
                      contentKey == 'labServiceStatusTable' ? <MonthlyLabServiceStatusTable apiData={chngdApiData !== '' ? chngdApiData : apiData} /> :
                        contentKey == 'labserviceStatusChart' ? <MonthlyLabServiceStatusChart apiData={chngdApiData !== '' ? chngdApiData : apiData} /> :
                          contentKey == 'radioServiceStatusTable' ? <MonthlyRadioServiceStatusTable apiData={chngdApiData !== '' ? chngdApiData : apiData} /> :
                            contentKey == 'radioserviceStatusChart' ? <MonthlyRadioServiceStatusChart apiData={chngdApiData !== '' ? chngdApiData : apiData} /> :
                              null
                  }
                </TabPane>
              </Tabs >
            </StickyContainer>
          </nav>
        </header >

      </div >
    </div>

  )
}

export default MonthlyDashboard;
const disabledDate = (current) => {
  return current && current > moment().endOf('day');
}
