import React from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'

const MonthlyPatientsChart = ({ apiData }) => {

  const optionsPatient = {
    chart: {
      id: 'PatientOverview',
      group: 'monthlyOverview',
      type: 'line',
      height: 200
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Patient Footfall',
      align: 'left',
      offsetX: 110
    },
    // xaxis: {
    //   // type: "datetime"
    //   categories: (() => { return ParsedOverviewChartData.Financial.TotalRevenue.map(e => e.x) })(),
    // },
    yaxis: [
      {
        seriesName: 'Patients',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => { return new Intl.NumberFormat('en-IN').format(value) },
          minWidth: 80
        },
        title: {
          text: "Patient Count",
          style: {
            color: '#141414',
          }
        },
      }
    ],
    tooltip: {
      theme: 'dark'
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let MalePatientsSeriesData = [], FemalePatientsSeriesData = [], TotalPatientsSeriesData = []

    apiData.forEach(md => {

      let malePatients = md.patientCount.Male ? md.patientCount.Male : 0
      let femalePatients = md.patientCount.Female ? md.patientCount.Female : 0
      let totalPatients = malePatients + femalePatients

      MalePatientsSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": malePatients
      })
      FemalePatientsSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": femalePatients
      })

      TotalPatientsSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": totalPatients
      })

    })

    return {
      PatientSeries: [
        {
          name: "Patients",
          data: TotalPatientsSeriesData,
          type: 'line'
        },
        {
          name: "Males",
          data: MalePatientsSeriesData,
          type: 'line'
        }, {
          name: "Females",
          data: FemalePatientsSeriesData,
          type: 'line'
        }
      ]
    }
  }

  return (
    <Chart options={optionsPatient} series={getSeriesData().PatientSeries} type="line" height={200} />
  )

}

export default MonthlyPatientsChart