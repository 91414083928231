exports.shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]

exports.deptsSubDepts = [
  {
    "dept": { "key": "radio", "display": "Radio" },
    "subDepts":
      [{ "key": "sono", "display": "Sonography" },
      { "key": "mri", "display": "MRI" },
      { "key": "ct", "display": "CT-Scan" },
      { "key": "usg", "display": "USG" }]
  },
  {
    "dept": { "key": "path", "display": "Pathology" },
    "subDepts":
      [{ "key": "hematology", "display": "Hematology" },
      { "key": "biochem", "display": "BioChemistry" },
      { "key": "renal", "display": "Renal" },
      { "key": "micro", "display": "Microbiology" }]
  },
  {
    "dept": { "key": "consultation", "display": "Consultation" },
    "subDepts":
      [{ "key": "new_visit", "display": "New Visit" },
      { "key": "new_tele", "display": "New Tele" },
      { "key": "follow_visit", "display": "Follow-up Visit" },
      { "key": "follow_tele", "display": "Follow-up Tele" }]
  }
]

exports.LabStatus = [
  "Test Ordered",
  "Sample Collected",
  "Sample Received",
  "Sample Recollected",
  "Sample under Processing",
  "Result Entry done",
  "Retesting",
  "Report Released",
  "Partial Report Released",
  "Provisional Report Released",
  "Final Report Released",
  "Send To Central Receiving",
  "Send To Department",
  "Department Accepted",
  "Department Rejected",
  "Send To Report Entry",
  "Result Validation Done",
  "Release for Sensitivity",
  "Accepted",
  "Rejected",
  "Specimen Accepted",
  "Gross Created",
  "Block Created",
  "Slide Created",
  "Out Source Sample",
  "Send For Incubation Obervation",
  "During Objservation",
  "Incubation Objservation Completed",
  "MacroScopic Examination",
  "MacroScopic Examination Completed",
  "During Biochemical Result Entry",
  "Biochemical Result Entry",
  "MicroScopic Examination",
  "Sensitivity Test",
  "Sensitivity Test Check",
  "Micro Tempalte Accepted",
  "Micro Tempalte Report Entry",
  "Micro Tempalte Report Validated",
  "Micro Tempalte Report Released"
]

exports.RadioStatus = [
  "Pending",
  "Provisional Release",
  "Final Release",
  "Report Release"
]