import React from 'react'
import Chart from "react-apexcharts";
import DummyData from "./DummyData"

const getCategs = () => DummyData.Alos.map(e => e.spec)

const getLosSeriesData = () => DummyData.Alos.map(e => e.los)

const getDischargeSeriesData = () => DummyData.Alos.map(e => e.discharges)

const AlosChart = () => {

  const getSeriesData = () => {
    return [{
      name: 'Length of Stay',
      type: 'column',
      data: getLosSeriesData()
    }, {
      name: 'Discharges',
      type: 'line',
      data: getDischargeSeriesData()
    }]
  }

  const getOptions = () => {
    return {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: 'ALOS Report'
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      // labels: getCategs(),
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false,
        },
        categories: getCategs()
      },
      yaxis: [{
        title: {
          text: 'Length of Stay',
        },
      }, {
        opposite: true,
        title: {
          text: 'Discharges'
        }
      }]
    }
  }

  return <Chart options={getOptions()} series={getSeriesData()} type="line" height={600} />

}

// const AlosChart = () => {

//   const getOptions = () => {
//     return {
//       chart: {
//         type: 'bar',
//         height: 600
//       },
//       plotOptions: {
//         bar: {
//           horizontal: true,
//         }
//       },
//       dataLabels: {
//         enabled: false
//       },
//       xaxis: {
//         categories: getCategs()
//       }
//     }
//   }

//   const getSeriesData = () => {
//     return [{
//       name: "Length of Stay",
//       type: "bar",
//       data: getLosSeriesData()
//     }, {
//       name: 'Discharges',
//       type: 'line',
//       data: getDischargeSeriesData()
//     }]
//   }

//   return (
//     <div>
//       <p>AlosChart</p>
//       <Chart options={getOptions()} series={getSeriesData()} type="bar" height={600} />
//     </div>
//   )
// }

export default AlosChart