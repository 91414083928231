import React from 'react';
import ReactApexChart from 'react-apexcharts';
// import { LabStatus } from '../data/AppStaticData';
interface props {
    apiData: any;
}
interface state {
    series: any,
    options: any,
    departmentList: any;
    workStatusNameList: any;
    totalCalculation: any;
}
export default class MonthlyLabServiceStatusChart extends React.Component<props, state> {
    constructor(props: any) {
        super(props);
        this.state = {

            series: [

            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    // text: 'Fiction Books Sales'
                },
                xaxis: {
                    categories: [

                    ],
                    // labels: {
                    //     formatter: function (val: any) {
                    //         return val + "K"
                    //     }
                    // }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                tooltip: {
                    // y: {
                    //     formatter: function (val: any) {
                    //         return val
                    //     }
                    // }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
            departmentList: [],
            workStatusNameList: [],
            totalCalculation: []

        };
        // this.state = {
        //     series: '',
        //     options: '',
        //     departmentList: ['Serology', 'Hematogy', 'Clinical Pathology', 'BIOCHEMISTRY', 'Histopathology', 'Microbiology'],
        //     status: ''
        // }
    };
    componentDidMount() {

        this.props.apiData.length !== null && this.props.apiData.map((data: any, index: any) => {
            if (data.statusWiseTestCount && data.statusWiseTestCount.length > 0 && data.statusWiseTestCount[0].lab && data.statusWiseTestCount[0].lab.length > 0) {
                data.statusWiseTestCount[0].lab.map((labName: any, index: number) => {
                    Object.values(labName).map((workStatus: any, index: any) => {
                        workStatus.map((work: any, index: any) => {
                            if (!this.state.workStatusNameList.includes(Object.keys(work)[0])) {
                                this.state.workStatusNameList.push(Object.keys(work)[0])
                            }
                        })
                    })
                    if (!this.state.departmentList.includes(Object.keys(labName)[0])) {
                        this.state.departmentList.push(Object.keys(labName)[0])
                    }
                })
            }
        })

        let xaxis = {};
        xaxis = { categories: [...this.state.departmentList] };
        this.setState({
            options: {
                ...this.state.options,
                xaxis
            }
        })
        let finalEntry: any[] = [];
        this.state.workStatusNameList.map((status: any, index: any) => {
            let countWorkStatusObj: any = {}
            countWorkStatusObj = { name: status };
            let data: any[] = [];
            this.state.departmentList.map((dept: any, index: any) => {
                let count: any = 0;
                this.props.apiData.map((data: any, index: any) => {
                    let countArray: any[] = [];
                    data.statusWiseTestCount[0].lab.map((labName: any, index: any) => {
                        if (Object.keys(labName)[0] == dept) {  //chk for same department

                            Object.values(labName).map((statusList: any, index: any) => {
                                Object.values(statusList).map((statusName: any, index: any) => {
                                    Object.keys(statusName).map((singleStatus: any, index: any) => {
                                        if (singleStatus == status) {
                                            count += Object.values(statusName)[0]
                                        }
                                    })
                                })
                            })

                        }
                    })
                })

                data.push(count)
            })
            countWorkStatusObj = {
                ...countWorkStatusObj,
                data: data
            }
            finalEntry.push(countWorkStatusObj)
        })

        this.setState({
            series: finalEntry
        })

    }

    render() {
        return (

            <>

                <div id="chart">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
                </div>
            </>

        );
    }
}



