import React from 'react'
import { Table } from 'antd'
import { RadioStatus } from '../data/AppStaticData'

const isNumber = (value) => typeof value === 'number' && isFinite(value)

const MonthlyRadioServiceStatusTable = ({ apiData }) => {
    const getTableCols = () => {
        let retCols = []

        retCols.push({
            title: 'Dept',
            key: 'dept',
            dataIndex: 'dept',
            fixed: window.innerWidth < 768 ? '' : 'left',
            width: window.innerWidth < 768 ? 80 : 120,


            render: (text) => {
                return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
            }
        })

        RadioStatus.forEach(ls => {
            retCols.push({
                title: ls,
                key: ls,
                dataIndex: ls,
                width: window.innerWidth < 768 ? 60 : 80,

                align: 'right',

                render: (text) => {
                    return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
                }
            })
        })

        return retCols
    }

    const getTableData = () => {
        let tableDataArray = []
        let insertedDeptList = []
        //insertedDeptList[0] is array of sub dept under radiology
        insertedDeptList[0] = []
        //insertedDeptList[1] is running total status count against sub dept at insertedDeptList[0]
        insertedDeptList[1] = []

        apiData && apiData.length > 0 && apiData.forEach((d, dIdx) => {
            d.statusWiseTestCount[1].radiology.forEach(dept => {
                let currDept = Object.keys(dept)[0]
                Object.values(dept)[0].forEach(deptStatus => {
                    let currStatus = Object.keys(deptStatus)[0]
                    let currStatusCount = Object.values(deptStatus)[0]
                    let rowObj = {}

                    if (insertedDeptList[0].indexOf(currDept) < 0) {
                        insertedDeptList[0].push(currDept)
                        rowObj['key'] = currDept
                        rowObj['dept'] = currDept
                        rowObj[currStatus] = currStatusCount
                        insertedDeptList[1][insertedDeptList[0].indexOf(currDept)] = {}
                        insertedDeptList[1][insertedDeptList[0].indexOf(currDept)][currStatus] = currStatusCount
                    } else {
                        rowObj = tableDataArray[insertedDeptList[0].indexOf(currDept)]
                        if (isNumber(rowObj[currStatus])) {
                            rowObj[currStatus] += currStatusCount
                            insertedDeptList[1][insertedDeptList[0].indexOf(currDept)][currStatus] += currStatusCount
                        } else {
                            rowObj[currStatus] = currStatusCount
                            insertedDeptList[1][insertedDeptList[0].indexOf(currDept)][currStatus] = currStatusCount
                        }
                    }
                    tableDataArray[insertedDeptList[0].indexOf(currDept)] = rowObj
                })
            })
        })

        tableDataArray.forEach((dept, tdIdx) => {
            RadioStatus.forEach(status => {
                if (!Object.keys(dept).includes(status)) {
                    tableDataArray[tdIdx][status] = 0
                    if (!isNumber(insertedDeptList[1][tdIdx][status])) insertedDeptList[1][tdIdx][status] = 0
                }
            })
        })

        tableDataArray.push({
            'key': 'total',
            'dept': 'Total'
        })

        RadioStatus.forEach((ls, lsIdx) => {
            let runningStatusTotal = 0
            insertedDeptList[1].forEach(insDept => {
                runningStatusTotal += insDept[ls]
            })
            tableDataArray[insertedDeptList[0].length][ls] = runningStatusTotal
        })

        return tableDataArray
    }

    return (
        <>
            <Table bordered columns={getTableCols()} dataSource={getTableData()}
                pagination={false} size="small"
                // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
                style={{ maxWidth: 'calc(100vw - 24px)' }}
                scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
            />
        </>
    )
}

export default MonthlyRadioServiceStatusTable