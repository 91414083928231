import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

const MonthlyPatientsTable = ({ apiData }) => {

  const getTableCols = () => {
    const retCols = []

    retCols.push({
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender',
      fixed: window.innerWidth < 768 ? '' : 'left',
      width: window.innerWidth < 768 ? 75 : 160,

      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData && apiData.length > 0 && apiData.forEach(dailyData => {
      retCols.push({
        title: moment(dailyData.trandate).format("MMM-YYYY"),
        key: `gender-${dailyData['trandate']}`,
        dataIndex: `gender-${dailyData['trandate']}`,
        width: 80,
        align: 'right',

        render: (text) => {
          return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
        }
      })
    })

    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      width: window.innerWidth < 768 ? 70 : 160,

      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    return retCols
  }

  const getTableData = () => {
    let tableDataArray = []
    let totalMales = 0
    let totalFemales = 0
    // tableDataArray[0], tableDataArray[1], tableDataArray[2] = {}
    tableDataArray[0] = {
      'key': 'Male',
      'gender': 'Male'
    }
    tableDataArray[1] = {
      'key': 'Female',
      'gender': 'Female'
    }
    tableDataArray[2] = {
      'key': 'Total',
      'gender': 'Total'
    }
    apiData && apiData.length > 0 && apiData.forEach(dd => {
      let currDate = dd.trandate
      let malePatients = dd.patientCount.Male ? dd.patientCount.Male : 0
      let femalePatients = dd.patientCount.Female ? dd.patientCount.Female : 0
      let totalPatients = malePatients + femalePatients
      tableDataArray[0][`gender-${currDate}`] = malePatients
      tableDataArray[1][`gender-${currDate}`] = femalePatients
      tableDataArray[2][`gender-${currDate}`] = totalPatients

      totalMales += malePatients
      totalFemales += femalePatients
    })

    tableDataArray[0]['total'] = totalMales
    tableDataArray[1][`total`] = totalFemales
    tableDataArray[2][`total`] = totalMales + totalFemales

    return tableDataArray
  }

  return (
    <Table bordered columns={getTableCols()} dataSource={getTableData()}
      pagination={false} size="small"
      // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
      style={{ maxWidth: 'calc(100vw - 24px)' }}
      scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
    />
  )

}

export default MonthlyPatientsTable