import React, { useState } from 'react'
import { Table, Button, Badge, Modal, Menu } from 'antd'
import { FundViewOutlined, FolderViewOutlined, DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import './DailyRevenueTable.css';

import GetApiData from '../utils/GetApiData';

// import moment from 'moment'

const { SubMenu } = Menu;



const DailyRevenueTable = ({ apiData, currencyInfo, apiHost }) => {
  // code for pop up
  console.log(window.innerWidth, "wwwwwwwwwwwww", currencyInfo)
  const [popUpTitle, setPopUpTitle] = useState("");
  const [subDeptNameTable, setubDeptNameTable] = useState('');
  const [modalTextValue, setModalTextValue] = useState('');
  const [visible, setVisible] = useState(false);

  const serviceRowData = [];

  if (visible) {




    let serviceUniqueArray = [];
    apiData.map((data, index) => {
      if (data.revenues.length > 0) {
        data.revenues.map((dept, index) => {
          Object.values(dept.subDepts).map((subDept, index) => {
            Object.keys(subDept).map((subD, index) => {
              if (subD == subDeptNameTable) {
                subDept.services.map((service, index) => {
                  console.log(service, "ssssssssssssssss")
                  if (!serviceUniqueArray.includes(Object.keys(service)[0])) {
                    serviceUniqueArray.push(Object.keys(service)[0])
                  }


                })
              }
            })

          })
        })
      }
    })

    let similarObj = {}
    apiData.map((data, index) => {
      similarObj = {
        ...similarObj,
        ['revenue-' + data.trandate]: 0,
        ['count-' + data.trandate]: 0
      }

    })
    let rightbottomCount = 0;
    serviceUniqueArray.map((ser, index) => {
      let rowObj = {
        index: `${ser}-${serviceRowData.length}`,
        Services: ser,
        ...similarObj
      }
      let rowServiceCount = 0
      apiData.map((data, index) => {
        data.count.map((dept, index) => {
          Object.keys(dept).map((_dept, index) => {

            if (modalTextValue.split("::")[0] == _dept) {

              dept.subDepts.map((subDept, index) => {
                Object.keys(subDept).map((subDeptName, index) => {
                  if (modalTextValue.split("::")[1] == subDeptName) {
                    // console.log(subDept, "subbbbbbbbbbbbbbbbbb")
                    subDept.services.map((service, index) => {
                      if (Object.keys(service)[0] == ser) {
                        rowObj = {
                          ...rowObj,
                          // ...dateRowObj,

                          ['count-' + data.trandate]: +`${Object.values(service)[0]}`,
                          // ['count-' + data.trandate]: +`${Object.values(service)[0].split("::")[1]}`

                        }
                        console.log(service, "ssssssssssssssss")

                        return;
                      }
                    })
                  }
                  return;
                })
              })
            }
          })
        })
        data.revenues.map((dept, index) => {
          Object.keys(dept).map((_dept, index) => {

            if (modalTextValue.split("::")[0] == _dept) {

              dept.subDepts.map((subDept, index) => {
                Object.keys(subDept).map((subDeptName, index) => {
                  if (modalTextValue.split("::")[1] == subDeptName) {
                    // console.log(subDept, "subbbbbbbbbbbbbbbbbb")
                    subDept.services.map((service, index) => {
                      if (Object.keys(service)[0] == ser) {
                        rowObj = {
                          ...rowObj,
                          // ...dateRowObj,

                          ['revenue-' + data.trandate]: +`${Object.values(service)[0]}`,
                          // ['count-' + data.trandate]: +`${Object.values(service)[0].split("::")[1]}`
                          total: rowServiceCount += (+Object.values(service)[0])

                        }

                        return;
                      }
                    })
                  }
                  return;
                })
              })
            }
          })
        })
      })
      rightbottomCount += rowObj.total
      console.log(rowObj.total, "hhhhhhhhhhhhhhhy")
      serviceRowData.push(rowObj)
    })
    console.log(serviceRowData, "jjjjjjjjjjjjjjj")
    let lstTotal = {

      key: "colTotal",
      Services: "Total",
      // children: [],


    }
    apiData.map((data, index) => {
      // let currDate = d.trandate;
      let revenueColumnTotal = 0;
      let countColumnTotal = 0;
      serviceRowData.map((tableData, index) => {
        let revenueName = 'revenue-' + data.trandate;
        let countName = 'count-' + data.trandate;
        if (Object.keys(tableData).includes(revenueName)) {

          revenueColumnTotal += (+tableData[revenueName])
        }
        if (Object.keys(tableData).includes(countName)) {

          countColumnTotal += (+tableData[countName])
        }


      })
      lstTotal = {
        ...lstTotal,
        [`revenue-${data.trandate}`]: revenueColumnTotal,
        [`count-${data.trandate}`]: countColumnTotal,
      }
      // rightbottomTotal += data.trandate
      console.log(revenueColumnTotal, data.trandate, "ttttttttttto")
    })

    let rtBottomTotal = {
      key: 'rtbttotal',
      total: rightbottomCount
    }
    lstTotal = {
      ...lstTotal,
      ...rtBottomTotal
    }
    console.log(lstTotal, "rowssssssssss")
    serviceRowData.push(lstTotal)
  }




  // code for pop up end



  //source or entitlement related states
  let sourceWiseList = []
  let entitlementWiseList = []


  const getCols = () => {
    let Cols = []

    Cols.push({
      title: 'Dept',
      dataIndex: 'dept',
      key: 'dept',
      // fixed: 'left',
      fixed: window.innerWidth < 768 ? '' : 'left',
      // width: 245
      width: window.innerWidth < 768 ? 200 : 245,
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData.forEach(dd => {

      if (dd.sourceWise && dd.sourceWise.length > 0) {
        dd.sourceWise.forEach(sw => sourceWiseList.indexOf(Object.values(sw)[0]) < 0 && sourceWiseList.push(Object.values(sw)[0]))
      }

      if (dd.entitlementWise && dd.entitlementWise.length > 0) {
        dd.entitlementWise.forEach(ew => {
          ew.payeeDetails.forEach(pd => {
            let ewName = `${ew.entitlement}: ${pd.payee}`

            entitlementWiseList.indexOf(ewName) < 0 && entitlementWiseList.push(ewName)
          })
        })
      }

      Cols.push({
        title: dd['trandate'],
        children: [
          {
            title: 'Revenue',
            key: `revenue-${dd['trandate']}`,
            dataIndex: `revenue-${dd['trandate']}`,
            // width: 160,
            width: window.innerWidth < 768 ? 120 : 160,
            align: 'right',
            render: ((text, rec, idx) => {
              // console.log(text, "tttttttttttttttt")
              let fontWeight = 'inherit'
              if (!rec.key.includes('subDept')) {
                fontWeight = 'bold'
                return (
                  <span style={{ fontWeight, fontSize: window.innerWidth < 768 ? "12px" : "15px" }} key={text}>
                    {
                      currencyInfo + new Intl.NumberFormat('en-IN').format(text)
                      // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)
                    }
                    &nbsp;
                  </span>
                )
              } else {

                return (

                  <span style={{ fontWeight, fontSize: window.innerWidth < 768 ? "12px" : "15px" }} key={text}>
                    {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text.toString().split("::")[0])}&nbsp; */}
                    {
                      currencyInfo + new Intl.NumberFormat('en-IN').format(text).toString().split("::")[0]
                    }
                  </span>
                )
              }
            })
          },
          {
            title: 'Count',
            key: `count-${dd['trandate']}`,
            dataIndex: `count-${dd['trandate']}`,
            // width: 80,
            width: window.innerWidth < 768 ? 60 : 80,
            align: 'right',
            render: ((text) => {
              return (
                <spna style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
                  <Badge className="rev-table-order-count" count={text} overflowCount={10000} showZero />
                </spna>
              )
            })
          }
        ]
      })
    })

    Cols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      // fixed: 'right',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      // width: 160,
      width: window.innerWidth < 768 ? 100 : 160,
      render: ((text, rec, idx) => {

        let fontWeight = 'inherit'
        if (!rec.key.includes('subDept')) fontWeight = 'bold'
        return (<span style={{ fontWeight, width: window.innerWidth < 768 ? 60 : 80, }}>
          {
            currencyInfo + new Intl.NumberFormat('en-IN').format(text)
          }
          {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
        </span>)
      })
    })

    return Cols
  }

  const getColsForService = () => {

    let Cols = []
    Cols.push({
      title: 'Services',
      dataIndex: 'Services',
      key: 'Services',
      // fixed: 'left',
      fixed: window.innerWidth < 768 ? '' : 'left',
      width: window.innerWidth < 768 ? 100 : 200,
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData.forEach(md => {
      Cols.push({
        title: md['trandate'],
        children: [
          {
            title: 'Revenue',
            key: `revenue-${md['trandate']}`,
            dataIndex: `revenue-${md['trandate']}`,
            // width: 160,
            width: window.innerWidth < 768 ? 120 : 160,
            align: 'right',
            render: ((text, rec, idx) => {
              // console.log(rec, "reccccccccccc")
              let fontWeight = 'inherit';
              let _return;
              if (text) {
                _return = <span style={{ fontWeight, fontSize: window.innerWidth < 768 ? "12px" : "15px" }} key={text}>
                  {
                    // currencyInfo + (+text)
                    currencyInfo + new Intl.NumberFormat('en-IN').format(+text)
                    // new Intl.NumberFormat('en-IN', {style: 'currency', currency: currencyInfo }).format(+text)
                  }
                </span>
              } else {
                _return = <span style={{ fontWeight, fontSize: window.innerWidth < 768 ? "12px" : "15px" }} key={text}>
                  {
                    currencyInfo + new Intl.NumberFormat('en-IN').format(0)
                    // new Intl.NumberFormat('en-IN', {style: 'currency', currency: currencyInfo }).format(0)
                  }

                </span>
              }
              return _return;
            })
          },
          {
            title: 'Count',
            key: `count-${md['trandate']}`,
            dataIndex: `count-${md['trandate']}`,
            // width: 80,
            width: window.innerWidth < 768 ? 60 : 80,
            align: 'right',
            render: ((text) => {
              return (
                <span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
                  <Badge className="rev-table-order-count" count={text ? text : 0} overflowCount={10000} showZero />
                </span>
              )
            })
          }
        ]
      })
    })

    Cols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      // fixed: 'right',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      width: window.innerWidth < 768 ? 100 : 160,
      render: ((text, rec, idx) => {
        let fontWeight = 'inherit'
        // if (!rec.key.includes('subDept')) fontWeight = 'bold'
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px", fontWeight: "inherit" }}> { currencyInfo + new Intl.NumberFormat('en-IN').format(text) // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)
        }</span>)
      })
    })

    return Cols;

  }
  const getTableData = (contentKey) => {

    let tableDataArray = []
    let insertedDeptList = []
    insertedDeptList[0] = [] // list of main depts
    insertedDeptList[1] = [] // list of sub depts under main dept
    insertedDeptList[2] = [] // consolidated total amt of main depts
    insertedDeptList[3] = []  // consolidated total amt of SUB depts
    insertedDeptList[4] = [] // consolidated total order COUNT of main depts
    insertedDeptList[5] = []  // consolidated total order COUNT of SUB depts

    let daywiseTotalRowObj = {
      'key': 'total',
      'mode': 'Total',
    }


    let countArray = [];
    let countArrayDayWise = {
      'key': 'total',
      'mode': 'Total',
    };
    let totalVerticalCount = 0;
    apiData.forEach((d, dIdx) => {
      let currDate = d.trandate



      if (d.payments.length == 0) {
        daywiseTotalRowObj[`revenue-${currDate}`] = 0
      }
      else {
        daywiseTotalRowObj[`revenue-${currDate}`] = d.payments.Cash
      }



      let revsForMap = []

      if (contentKey === 'overall') {
        revsForMap = d.revenues
      } else if (contentKey.indexOf('swl-') === 0) {
        d.sourceWise.forEach(sw => {
          if (contentKey === `swl-${Object.values(sw)[0]}`) revsForMap = Object.values(sw)[1]
        })
      } else if (contentKey.indexOf('ewl-') === 0) {
        d.entitlementWise.forEach(ew => {
          if (contentKey.indexOf(`ewl-${ew.entitlement}`) === 0) {
            ew.payeeDetails.forEach(pd => {
              if (contentKey === `ewl-${ew.entitlement}: ${pd.payee}`) {
                revsForMap = pd.revenues
              }
            })
          }
        })
      }


      if (revsForMap.length > 0) {
        revsForMap.forEach(rev => {
          // if (rev == null) return false

          let deptName = Object.keys(rev)[0]
          let deptRev = Object.values(rev)[0]

          let rowObj = {}
          if (insertedDeptList[0].indexOf(deptName) < 0) {
            insertedDeptList[0].push(deptName)
            insertedDeptList[1].push([])
            insertedDeptList[2].push(0)
            insertedDeptList[3].push([])
            rowObj['key'] = deptName
            rowObj['dept'] = deptName
            rowObj['children'] = []
          } else {
            rowObj = tableDataArray[insertedDeptList[0].indexOf(deptName)]
          }

          rowObj[`revenue-${currDate}`] = deptRev

          let insertedDeptListIdx = insertedDeptList[0].indexOf(deptName)
          insertedDeptList[2][insertedDeptListIdx] += deptRev

          rev.subDepts.forEach(sdRev => {

            console.log(sdRev, "Revvvvvvvvvvvvvvvvvvv")
            let subDeptName = Object.keys(sdRev)[0]
            let subDeptRev = Object.values(sdRev)[0]
            let childRowObj = {}

            if (insertedDeptList[1][insertedDeptListIdx].indexOf(subDeptName) < 0) {
              insertedDeptList[1][insertedDeptListIdx].push(subDeptName)
              insertedDeptList[3][insertedDeptListIdx].push(0)
              childRowObj['key'] = `subDept-${subDeptName}`
              // childRowObj['dept'] = subDeptName;

              childRowObj['dept'] = <span>{subDeptName} &nbsp;<Button shape="circle" icon={<FundViewOutlined />}
                onClick={() => { setVisible(true); setubDeptNameTable(subDeptName); setModalTextValue(deptName + "::" + subDeptName) }} /></span>
              childRowObj[`revenue-${currDate}`] = `${subDeptRev}::${currDate}::${deptName}::${subDeptName}`

              rowObj['children'].push(childRowObj)

            } else {
              childRowObj = rowObj['children'][insertedDeptList[1][insertedDeptListIdx].indexOf(subDeptName)]
              childRowObj[`revenue-${currDate}`] = `${subDeptRev}::${currDate}::${deptName}::${subDeptName}`
              rowObj['children'][insertedDeptList[1][insertedDeptListIdx]] = childRowObj
            }
            let subDeptListIdx = insertedDeptList[1][insertedDeptListIdx].indexOf(subDeptName)
            insertedDeptList[3][insertedDeptListIdx][subDeptListIdx] += subDeptRev
          })
          tableDataArray[insertedDeptList[0].indexOf(deptName)] = rowObj;

        }) // END of d.revenues.forEach

      } else {

      }




      d.payments.map((pay, index) => {
        console.log(pay.Cash, "pppppppppppppppppppp")
        if (pay.length == 0) {
          daywiseTotalRowObj[`revenue-${currDate}`] = 0
        }
        else {
          daywiseTotalRowObj[`revenue-${currDate}`] = pay.Cash
        }
      })

      let daywiseTotalCount = 0;
      countArray.push(d.count);
      countArray.map((cnt, index) => {
        if (cnt.length < 1) {
          countArrayDayWise[`count-${d.trandate}`] = 0
        }
        else {
          let dayWisecount = 0;
          cnt.map((cArr, index) => {
            cArr.subDepts.map((sub, index) => {
              dayWisecount += sub.services.length
            })

          })
          countArrayDayWise[`count-${d.trandate}`] = dayWisecount

        }
      })



    })





    tableDataArray.forEach((td, tdIdx) => {
      let tdKeysList = Object.keys(td)
      let currDeptCountIdxApiData;
      apiData.forEach(ad => {

        let currDept = Object.values(td)[tdKeysList.indexOf('key')]
        let countForMap = []

        if (contentKey === 'overall') {
          countForMap = ad.count
        } else if (contentKey.indexOf('swl-') === 0) {
          ad.sourceWise.forEach(sw => {
            if (contentKey === `swl-${Object.values(sw)[0]}`) countForMap = Object.values(sw)[2]
          })
        } else if (contentKey.indexOf('ewl-') === 0) {
          ad.entitlementWise.forEach(ew => {
            if (contentKey.indexOf(`ewl-${ew.entitlement}`) === 0) {
              ew.payeeDetails.forEach(pd => {
                if (contentKey === `ewl-${ew.entitlement}: ${pd.payee}`) {
                  countForMap = pd.count
                }
              })
            }
          })
        }

        countForMap.length > 0 && countForMap.map((adc, adcIdx) => {
          for (const [key, value] of Object.entries(adc)) {
            if (key == currDept) {
              currDeptCountIdxApiData = adcIdx
              tableDataArray[tdIdx][`count-${ad.trandate}`] = value;

            }
          }
        })

        if (!tdKeysList.includes(`revenue-${ad.trandate}`)) {
          tableDataArray[tdIdx][`revenue-${ad.trandate}`] = 0
          tableDataArray[tdIdx][`count-${ad.trandate}`] = 0
        }

        td.children.forEach((cd, cdIdx) => {

          let cdKeysList = Object.keys(cd)
          let currSubDept = Object.values(cd)[cdKeysList.indexOf('dept')].props.children[0];

          typeof countForMap[currDeptCountIdxApiData] !== 'undefined' && countForMap.length > 0 && countForMap[currDeptCountIdxApiData].subDepts.map(sd => {
            if (Object.keys(sd)[0] == currSubDept) {

              tableDataArray[tdIdx]['children'][cdIdx][`count-${ad.trandate}`] = Object.values(sd)[0]
            }
          })

          if (!cdKeysList.includes(`revenue-${ad.trandate}`)) {
            tableDataArray[tdIdx]['children'][cdIdx][`revenue-${ad.trandate}`] = 0
            tableDataArray[tdIdx]['children'][cdIdx][`count-${ad.trandate}`] = 0
          }
          tableDataArray[tdIdx]['children'][cdIdx]['total'] = insertedDeptList[3][tdIdx][cdIdx]
        })
      })
      tableDataArray[tdIdx]['total'] = insertedDeptList[2][tdIdx];

      totalVerticalCount += tableDataArray[tdIdx]['total'];

    })

    let rightTotal = {
      key: 'total',
      total: totalVerticalCount,
    }

    // console.log(tableDataArray, "ttttttttttttttttt")
    let lstTotal = {

      key: "colTotal",
      dept: "Total",
      // children: [],


    }
    apiData.map((data, index) => {
      // let currDate = d.trandate;
      let revenueColumnTotal = 0;
      let countColumnTotal = 0;
      tableDataArray.map((tableData, index) => {
        let revenueName = 'revenue-' + data.trandate;
        let countName = 'count-' + data.trandate;
        if (Object.keys(tableData).includes(revenueName)) {

          revenueColumnTotal += (+tableData[revenueName])
        }
        if (Object.keys(tableData).includes(countName)) {

          countColumnTotal += (+tableData[countName])
        }


      })
      lstTotal = {
        ...lstTotal,
        [`revenue-${data.trandate}`]: revenueColumnTotal,
        [`count-${data.trandate}`]: countColumnTotal,
      }
      // rightbottomTotal += data.trandate
      console.log(revenueColumnTotal, data.trandate, "ttttttttttto")
    })

    lstTotal = {
      ...lstTotal,
      ...rightTotal
    }
    tableDataArray.push(lstTotal);
    return tableDataArray;
  }
  const fnDownloadReport = (pdfAction) => {
    console.log(apiHost, "hosttttttttt")
    let FromDate = apiData[0].trandate
    let ToDate = apiData[apiData.length - 1].trandate;
    let organization_id = localStorage.getItem("organisationId");
    let unit_id = apiData[0].unitId;
    GetApiData.downloadRevenueReport({
      FromDate,
      ToDate,
      organization_id,
      unit_id,
      apiHost,
      pdfAction
    })
      .then(data => {
        if (pdfAction == "view") {
          let pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>")

        }

      }).catch(err => {
        console.log("eeeeeeeee", err)
      })
  }

  //core or base states
  const [cols, setCols] = useState(getCols())
  const [colsForService, setColsForService] = useState(getColsForService())
  const [contentKey, setContentKey] = useState('overall')
  const [tableData, setTableData] = useState(getTableData('overall'))
  // modal related states


  const [modalServices, setModalServices] = useState([]);


  return (

    <>
      <div className="tabletr">
        <Modal
          title={`${modalTextValue.split("::")[0]} || ${modalTextValue.split("::")[1]} `}
          centered
          visible={visible}
          destroyOnClose={true}
          maskClosable={true}
          footer={null}
          onOk={() => {
            setModalServices([])
            setVisible(false)
            Modal.destroyAll();
          }}
          closable={true}
          onCancel={() => {
            setModalServices([])
            setVisible(false)
            Modal.destroyAll();
          }}
          width={"85%"}
        >
          <Table bordered columns={colsForService} dataSource={serviceRowData} pagination={false} size="small"
            style={{ maxWidth: 'calc(100vw - 24px)' }} scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}

          />



        </Modal>
      </div>
      <Menu onClick={e => {
        setContentKey(e.key)
        setTableData(getTableData(e.key))
      }} selectedKeys={[contentKey]} mode="horizontal">
        <Menu.Item key="overall" icon={<FundViewOutlined />}>
          Overall
        </Menu.Item>

        <SubMenu key="bySource" icon={<FolderViewOutlined />} title="Group By Source">
          {sourceWiseList.map(swl => <Menu.Item key={`swl-${swl}`}>{swl}</Menu.Item>)}
        </SubMenu>

        <SubMenu key="byEntitlement" icon={<FolderViewOutlined />} title="Group By Entitlement">
          {entitlementWiseList.map(ewl => <Menu.Item key={`ewl-${ewl}`}>{ewl}</Menu.Item>)}
        </SubMenu>
      </Menu>
      <br />
      <Table bordered columns={cols} dataSource={tableData} pagination={false} size="small" style={{ maxWidth: 'calc(100vw - 24px)' }} scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }} />
      {
        apiData && apiData.length > 0 &&
        <>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold'
          }} onClick={() => {
            fnDownloadReport("view")
          }}>
            <FilePdfOutlined /> View  PDF
        </div>

          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold', clear: "both"
          }} onClick={() => {
            fnDownloadReport("download")
            // fnDownloadDemo()
          }}>
            <DownloadOutlined /> Download  PDF

        </div>
        </>
      }
    </>
  )
}

export default DailyRevenueTable;