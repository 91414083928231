import React from 'react'
import Chart from "react-apexcharts";
import moment from 'moment'

function generateDayWiseTimeSeries(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = baseval;
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push([x, y]);
    // series.push([moment(new Date(baseval)).format("DD-MM-YYYY"), y]);
    baseval += 86400000;
    i++;
  }
  return series;
}

class ArpbChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        name: 'General Ward',
        data: generateDayWiseTimeSeries(new Date('11 Nov 2020').getTime(), 20, {
          min: 10,
          max: 60
        })
      }],
      options: {
        chart: {
          id: 'fb',
          group: 'social',
          type: 'line',
          height: 160
        },
        colors: ['#008FFB'],
        yaxis: {
          labels: {
            minWidth: 40,
            formatter: (value) => {
              return value + " lacs"
            },
          }
        },
        xaxis: {
          type: 'datetime'
        }
      },

      seriesLine2: [{
        name: 'Delux Room',
        data: generateDayWiseTimeSeries(new Date('11 Nov 2020').getTime(), 20, {
          min: 10,
          max: 30
        })
      }],
      optionsLine2: {
        chart: {
          id: 'tw',
          group: 'social',
          type: 'line',
          height: 160
        },
        colors: ['#546E7A'],
        yaxis: {
          labels: {
            minWidth: 40,
            formatter: (value) => {
              return value + " lacs"
            },
          }
        },
        xaxis: {
          type: 'datetime'
        }
      },

      seriesArea: [{
        name: 'VIP Room',
        data: generateDayWiseTimeSeries(new Date('11 Nov 2020').getTime(), 20, {
          min: 10,
          max: 60
        })
      }],
      optionsArea: {
        chart: {
          id: 'yt',
          group: 'social',
          type: 'area',
          height: 160
        },
        colors: ['#00E396'],
        yaxis: {
          labels: {
            minWidth: 40,
            formatter: (value) => {
              return value + " lacs"
            },
          }
        },
        xaxis: {
          type: 'datetime'
        }
      },


    };
  }

  render() {
    return (


      <div id="wrapper">
        <div id="chart-line">
          <p>General Ward</p>
          <Chart options={this.state.options} series={this.state.series} type="line" height={240} />
        </div>
        <div id="chart-line2">
          <p>Delux Room</p>
          <Chart options={this.state.optionsLine2} series={this.state.seriesLine2} type="line" height={240} />
        </div>
        <div id="chart-area">
          <p>VIP Room</p>
          <Chart options={this.state.optionsArea} series={this.state.seriesArea} type="line" height={240} />
        </div>
      </div>
    );
  }
}



export default ArpbChart