import React from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'
import { Statistic, Row, Col, Card } from 'antd';
import PatientSemiDonut from '../Pocs/PatientSemiDonut'
import PatientIpOpDonut from '../Pocs/PatientIpOpDonut'
import PatientTypeRadial from '../Pocs/PatientTypeRadial'
import RevenueChart from "../Pocs/RevenueChart"
import { BellOutlined, DownCircleOutlined, UserOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

const DailyOverviewChart = ({ apiData, currencyInfo, isPoc }) => {

  const optionsFinancial = {
    chart: {
      id: 'financialOverview',
      group: 'monthlyOverview',
      type: 'line',
      stacked: false,
      height: 400,
      events: {
        mouseMove: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Financial Analysis',
      align: 'left',
      offsetX: 110
    },

    colors: ['#008FFB', '#00E396', '#F15B46'],
    yaxis: [
      {
        seriesName: 'Revenue',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)

            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'KES' }).format(value) 
          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        }
      }
    ],
    tooltip: {
      theme: "dark"
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const optionsPatient = {
    chart: {
      id: 'PatientOverview',
      group: 'monthlyOverview',
      type: 'line',
      height: 200
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Patient Footfall',
      align: 'left',
      offsetX: 110
    },
    // xaxis: {
    //   // type: "datetime"
    //   categories: (() => { return ParsedOverviewChartData.Financial.TotalRevenue.map(e => e.x) })(),
    // },
    yaxis: [
      {
        seriesName: 'Patients',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => { return new Intl.NumberFormat('en-IN').format(value) },
          minWidth: 80
        },
        title: {
          text: "Patient Count",
          style: {
            color: '#141414',
          }
        },
      }
    ],
    tooltip: {
      theme: 'dark'
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let RevenueSeriesData = [], CollectionSeriesData = [], OutstandingSeriesData = []
    let MalePatientsSeriesData = [], FemalePatientsSeriesData = [], TotalPatientsSeriesData = []

    apiData !== null && apiData.forEach(md => {
      let totalMonthlyRev = 0
      let totalMonthlyCollection = 0
      let totalMonthlyOutstandings = 0

      let malePatients = md.patientCount.Male ? md.patientCount.Male : 0
      let femalePatients = md.patientCount.Female ? md.patientCount.Female : 0
      let totalPatients = malePatients + femalePatients

      md.revenues.forEach(mrev => {
        if (mrev == null) return
        totalMonthlyRev += Object.values(mrev)[0]
      })

      if (md.outstandings && md.outstandings.length > 0) {
        md.outstandings.forEach(out => {
          totalMonthlyOutstandings += Object.values(out)[0]
        })
      }

      md.payments.forEach(mPayment => {
        totalMonthlyCollection += Object.values(mPayment)[0]
      })

      RevenueSeriesData.push({
        "x": moment(md.trandate).format("DD-MM-YYYY"),
        "y": totalMonthlyRev
      })

      CollectionSeriesData.push({
        "x": moment(md.trandate).format("DD-MM-YYYY"),
        "y": totalMonthlyCollection
      })

      OutstandingSeriesData.push({
        "x": moment(md.trandate).format("DD-MM-YYYY"),
        "y": totalMonthlyOutstandings
      })

      MalePatientsSeriesData.push({
        "x": moment(md.trandate).format("DD-MM-YYYY"),
        "y": malePatients
      })
      FemalePatientsSeriesData.push({
        "x": moment(md.trandate).format("DD-MM-YYYY"),
        "y": femalePatients
      })

      TotalPatientsSeriesData.push({
        "x": moment(md.trandate).format("DD-MM-YYYY"),
        "y": totalPatients
      })

    })

    return {
      FinancialSeries: [
        {
          name: "Revenue",
          data: RevenueSeriesData,
          type: 'line'
        },
        {
          name: "Collection",
          data: CollectionSeriesData,
          type: 'line'
        },
        {
          name: "Outstanding",
          data: OutstandingSeriesData,
          type: 'line'
        }
      ],
      PatientSeries: [
        {
          name: "Patients",
          data: TotalPatientsSeriesData,
          type: 'line'
        },
        {
          name: "Males",
          data: MalePatientsSeriesData,
          type: 'line'
        }, {
          name: "Females",
          data: FemalePatientsSeriesData,
          type: 'line'
        }
      ]
    }
  }

  if (isPoc) {

    const ipPats = Math.round(23 / 100 * getSeriesData().PatientSeries[0].data[0]['y'])
    const opPats = getSeriesData().PatientSeries[0].data[0]['y'] - ipPats

    return (
      <>
        <br />

        <Row gutter={16}>
          <Col span={4} >
            <Card style={{ borderLeft: "4px solid #008080" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Revenue"
                value={getSeriesData().FinancialSeries[0].data[0]['y']}
                formatter={value => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)}
                valueStyle={{ color: '#008080' }}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ borderLeft: "4px solid #3f8600" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Collection"
                value={getSeriesData().FinancialSeries[1].data[0]['y']}
                formatter={value => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ borderLeft: "4px solid #cf1322" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Expenses"
                value={parseFloat('213212.31')}
                formatter={value => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)}
                valueStyle={{ color: '#cf1322' }}
              />
            </Card>
          </Col>
          <Col span={3} >
            <Card style={{ borderLeft: "4px solid #3f8600" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Admission"
                value={getSeriesData().PatientSeries[1].data[0]['y']}
                valueStyle={{ color: '#3f8600' }}
                prefix={<RightCircleOutlined />}
              />
            </Card>
          </Col>
          <Col span={3}>
            <Card style={{ borderLeft: "4px solid #cf1322" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Discharged"
                value={getSeriesData().PatientSeries[2].data[0]['y']}
                valueStyle={{ color: '#cf1322' }}
                prefix={<LeftCircleOutlined />}
              />
            </Card>
          </Col>
          <Col span={3} >
            <Card style={{ borderLeft: "4px solid #008efb" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Patients"
                value={getSeriesData().PatientSeries[0].data[0]['y']}
                valueStyle={{ color: '#008efb' }}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col span={3}>
            <Card style={{ borderLeft: "4px solid #4e4e4e" }} className="card-box-shadow col-chart-bg">
              <Statistic
                title="Occupancy"
                value={68}
                valueStyle={{ color: '#4e4e4e' }}
                prefix={<BellOutlined />}
                suffix={" %"}
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={12}>
            <Card className={"col-chart-bg"} hoverable>
              <RevenueChart
                _revenue={getSeriesData().FinancialSeries[0].data[0]['y']}
                _collections={getSeriesData().FinancialSeries[1].data[0]['y']}
                _outstanding={getSeriesData().FinancialSeries[2].data[0]['y']}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card className={"col-chart-bg"} style={{ height: "100%" }} hoverable>
              <PatientIpOpDonut ip={ipPats} op={opPats} />
            </Card>
          </Col>
          <Col span={6}>
            <Card className={"col-chart-bg"} style={{ height: "100%" }} hoverable>
              <PatientTypeRadial />
            </Card>
          </Col>
        </Row>
      </>
    )
  }
  else {
    return (
      <>
        <div id="financial-chart">
          <br />
          <Chart options={optionsFinancial} series={getSeriesData().FinancialSeries} type="line" height={400} />
        </div>
        <div id="patient-chart">
          <br />
          <Chart options={optionsPatient} series={getSeriesData().PatientSeries} type="line" height={200} />
          <br />
        </div>
      </>
    )
  }

}

export default DailyOverviewChart
