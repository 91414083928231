import React from 'react'
import Chart from "react-apexcharts";

const DailyRevenueChart = ({ apiData, data, }) => {

  const ParsedOverviewChartData = JSON.parse(data).OverviewChartData

  const getTotalRevOptions = (() => {
    return ({
      chart: {
        type: 'area',
        height: 300,
        stacked: false,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: "zoom"
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Consolidated Revenue',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        // categories: (() => { return ParsedOverviewChartData.Financial.TotalRevenue.map(e => e.x) })(),
        type: "datetime"
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 500, 1000]
        }
      },
      yaxis: {
        // seriesName: 'Revenue',
        // axisTicks: {
        //   show: true,
        // },
        // axisBorder: {
        //   show: true,
        //   color: '#008FFB'
        // },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => { return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value) },
          // minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        },
        // tooltip: {
        //   enabled: false
        // },
      },
      tooltip: {
        shared: false,
        theme: 'dark',
        y: {
          formatter: (value) => { return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value) },
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
      }
    })
  })

  const getTotalRevSeries = (() => {
    // console.log(ParsedOverviewChartData.Financial.TotalRevenue)
    return ([{
      name: "Revenue",
      // data: (() => { return ParsedOverviewChartData.Financial.TotalRevenue.map(e => e.y) })(),
      data: ParsedOverviewChartData.Financial.TotalRevenue
    }])
  })

  return (
    <>
      <Chart options={getTotalRevOptions()} series={getTotalRevSeries()} type="area" height={300} />
      <br />
      {/* <Chart options={getSubDeptsRevOptions()} series={getSubDeptsRevSeries()} type="line" height={400} /> */}
    </>
  )
}

export default DailyRevenueChart