import axios from 'axios'
import moment from 'moment';
import LoadingSvg from '../Layout/LoadingSvg';
// const FileDownload = require('js-file-download');
const fndownloadPDF = (pdfString, fileName) => {
  console.log("hhhhhhhjkj")
  const linkSource = `data:application/pdf;base64,${pdfString}`;
  const downloadLink = document.createElement("a");
  // const fileName = "vct_illustration.pdf";

  downloadLink.href = linkSource;

  downloadLink.download = `${fileName}` + ".pdf";
  downloadLink.click();
}
let _organization_id = localStorage.getItem("organisationId")

const daily = async ({
  organizationId = 1,
  unitId,
  fromDate = "2020-07-01",
  toDate = "2020-07-31",
  apiHost
}) => {
  console.log(organizationId, unitId, fromDate, toDate, apiHost, "qqqqqqqqqqqe")
  let organizationId_ = localStorage.getItem("organisationId")
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getAllCollectionList`, {
      "organizationId": organizationId_,
      "unitId": unitId,
      "fromDate": new Date(fromDate).toLocaleDateString('en-CA'),
      "toDate": new Date(toDate).toLocaleDateString('en-CA'),
      "period": "daily",
      "entitlement": true,
      "source": true,
      "revenue": true,
      "count": true,
      "patientCount": true,
      "payments": true,
      "outstandings": true,
      "discount": true,
      "expense": true,
      "statusWiseTestCount": true
    })
      .then(({ data }) => {
        resolve(data.object)
      }).catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const monthly = async ({ startMonth = null, endMonth = null, apiHost, organizationId, unitId }) => {

  let organizationId_ = localStorage.getItem("organisationId")
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getAllCollectionList`, {
      "organizationId": organizationId_,
      "unitId": unitId,
      "fromDate": moment(startMonth).format('YYYY-MM'),
      "toDate": moment(endMonth).format('YYYY-MM'),
      "period": "monthly",
      "entitlement": true,
      "source": true,
      "revenue": true,
      "count": true,
      "patientCount": true,
      "payments": true,
      "outstandings": true,
      "discount": true,
      "expense": true,
      "statusWiseTestCount": true
    })
      .then(({ data }) => {
        resolve(data.object)
      })
  })
}

const Login = async ({ userName, password, organizationId, apiHost, unitId }) => {
  let organizationId_ = localStorage.getItem("organisationId")
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/adt/userLogin`, {
      "organizationId": organizationId_,
      "userName": userName,
      "password": password,
      "unitId": unitId,
      "macAddress": ""
    })
      .then(({ data }) => {

        resolve(data)
      }).catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const downloadDiscountReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost,
  pdfAction
}) => {
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/discountPatientWiseBIReport`, {
      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": _organization_id,
      "unitId": unit_id
    })
      .then(({ data }) => {
        if (pdfAction == "download") {
          fndownloadPDF(data.object, "discountReport")
        }


        resolve(data.object)
      }).catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}


const downloadExpenseReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost, pdfAction
}) => {
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/miscellaneousExpenseBIReport`, {
      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": _organization_id,
      "unitId": unit_id
    })
      .then(({ data }) => {
        if (pdfAction == "download") {
          fndownloadPDF(data.object, "expenseReport")
        }
        resolve(data.object)
      }).catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const downloadRevenueReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost,
  pdfAction
}) => {
  console.log(apiHost, FromDate, ToDate, organization_id, unit_id, "hhhhhhhhhhh")

  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/salesRevenureportBIReport`, {
      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": _organization_id,
      "unitId": unit_id
    }
    )
      .then(({ data }) => {
        if (pdfAction == "download") {
          fndownloadPDF(data.object, "revenueReport")
        }
        resolve(data.object)
      })
      .catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const downloadOutstandingReport = async ({
  FromDate = "2020-07-01",
  ToDate = "2020-07-31",
  organization_id = 1,
  unit_id,
  apiHost,
  pdfAction
}) => {
  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/api/global/getOutstandingBIReport`, {
      "fromDate": FromDate.toString(),
      "toDate": ToDate.toString(),
      "organizationId": _organization_id,
      "unitId": unit_id

    })
      .then(({ data }) => {
        if (pdfAction == "download") {
          fndownloadPDF(data.object, "outstandingReport")
        }
        resolve(data.object)
      })
      .catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}

const getOrganisationList = async ({ subDomain, apiHost }) => {
  let _subDomain = subDomain.trim();
  return new Promise((resolve, reject) => {
    return axios.get(`${apiHost}/adt/organizationMasterList/${_subDomain}`)
      .then(({ data }) => {
        resolve(data.listObject)
      }).catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}
const getUnits = async ({ organizationId = 1, apiHost }) => {

  return new Promise((resolve, reject) => {
    return axios.post(`${apiHost}/adt/getUnitByOrganization`, {
      organizationId
    })
      .then(({ data }) => {
        let respUnitsList = data.listObject.map(u => {
          return {
            unitId: u.unitId,
            unitName: u.unitName,
          }
        })
        resolve(respUnitsList)
      })
  })
}

const getCurrencyInfo = async ({ unitId, apiHost }) => {
  return new Promise((resolve, reject) => {
    return axios.get(`${apiHost}/api/global/currencies/${unitId}`)
      .then(({ data }) => {
        if (data.symbol == "INR") {
          resolve("₹");
        }
        else {
          resolve(data.symbol)
        }
      }).catch(err => {
        console.log(err, "eeeeeeeeeeeeeeeee")
      })
  })
}
export default {
  daily, monthly, getUnits, Login, getOrganisationList, downloadDiscountReport, downloadExpenseReport
  , downloadRevenueReport, downloadOutstandingReport, getCurrencyInfo
}