import React from 'react'
import Chart from "react-apexcharts";

const TopDeptsLine = () => {

  const series = () => {
    return [
      {
        name: "Radiology",
        data: [28, 29, 33, 36, 32, 32, 33]
      },
      {
        name: "Cardiology",
        data: [26, 27, 29, 31, 28, 26, 29]
      },
      {
        name: "Orthopaedic",
        data: [14, 16, 13, 17, 12, 16, 19]
      }
    ]
  }

  const options = () => {
    return {
      chart: {
        height: 360,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA', '#545454', '#cf1322'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Top 3 Departments by Revenue',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ['15-Jan', '16-Jan', '17-Jan', '18-Jan', '19-Jan', '20-Jan', '21-Jan'],
        title: {
          text: 'Date'
        }
      },
      yaxis: {
        title: {
          text: 'Revenue (in thousand)'
        },
        min: 5,
        max: 40
      },
      // legend: {
      //   position: 'top',
      //   horizontalAlign: 'right',
      //   floating: true,
      //   offsetY: -25,
      //   offsetX: -5
      // }
    }
  }

  return (
    <div id="wrapper">
      <div id="chart-line">
        <Chart options={options()} series={series()} type="line" height={380} />
      </div>
    </div>
  );

}

export default TopDeptsLine