import React from 'react'
import DailyOverviewChart from "../DailyView/DailyOverviewChart"
import AlosChartDash from "./AlosChartDash"
import TopDeptsLine from "./TopDeptsLine"
import ArpbChartDash from "./ArpbChartDash"
import DynamicRevenue from "./DynamicRevenue"
import { Row, Col, Statistic, Card } from "antd"
import DebtorCreditor from "./DebtorCreditor"

const YdayDash = ({ apiData, currencyInfo }) => {

  return (
    <>
      <DailyOverviewChart isPoc={true} apiData={apiData} currencyInfo={currencyInfo} />
      <br />
      <Row gutter={16}>
        <Col span={8}>
          <Card style={{ borderLeft: "4px solid #3399FF" }} className="card-box-shadow col-chart-bg">
            <Statistic
              title="Avg. Length of Stay"
              value={6.5}
              precision={2}
              suffix="days"
              valueStyle={{ color: '#3399FF' }}
            />
          </Card>
        </Col>
        <Col span={8} >
          <Card style={{ borderLeft: "4px solid #4e4e4e" }} className="card-box-shadow col-chart-bg">
            <Statistic
              title="Avg. Revenue by Dept."
              value={parseFloat('23330.12')}
              precision={2}
              valueStyle={{ color: '#4e4e4e' }}
              formatter={value => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)}
            />
          </Card>
        </Col>
        <Col span={8} >
          <Card style={{ borderLeft: "4px solid #8000ff" }} className="card-box-shadow col-chart-bg">
            <Statistic
              title="Avg. Revenue by Bed"
              value={parseFloat('3138.75')}
              precision={2}
              valueStyle={{ color: '#8000ff' }}
              formatter={value => new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)}
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={8} >
          <Card className={"col-chart-bg"} hoverable>
            <AlosChartDash />
          </Card>
        </Col>
        <Col span={8} >
          <Card className={"col-chart-bg"} hoverable>
            <TopDeptsLine />
          </Card>
        </Col>
        <Col span={8}>
          <Card className={"col-chart-bg"} hoverable>
            <ArpbChartDash />
          </Card>
        </Col>
      </Row>

      <br />

      <Row gutter={16}>
        <Col span={12}>
          <Card className={"col-chart-bg"} hoverable>
            <DynamicRevenue />
          </Card>
        </Col>
        <Col span={12}>
          <Card className={"col-chart-bg"} hoverable>
            <DebtorCreditor />
          </Card>
        </Col>
      </Row>

    </>
  )
}

export default YdayDash