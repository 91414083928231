import React from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'

const MonthlyOverviewChart = ({ apiData, currencyInfo }) => {

  const optionsFinancial = {
    chart: {
      id: 'financialOverview',
      group: 'monthlyOverview',
      type: 'line',
      stacked: false,
      height: 400,
      events: {
        mouseMove: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Financial Analysis',
      align: 'left',
      offsetX: 110
    },

    colors: ['#008FFB', '#00E396', '#F15B46'],
    yaxis: [
      {
        seriesName: 'Revenue',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)
            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value) 
          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        }
      }
    ],
    tooltip: {
      theme: "dark"
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const optionsPatient = {
    chart: {
      id: 'PatientOverview',
      group: 'monthlyOverview',
      type: 'line',
      height: 200
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Patient Footfall',
      align: 'left',
      offsetX: 110
    },
    // xaxis: {
    //   // type: "datetime"
    //   categories: (() => { return ParsedOverviewChartData.Financial.TotalRevenue.map(e => e.x) })(),
    // },
    yaxis: [
      {
        seriesName: 'Patients',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => { return new Intl.NumberFormat('en-IN').format(value) },
          minWidth: 80
        },
        title: {
          text: "Patient Count",
          style: {
            color: '#141414',
          }
        },
      }
    ],
    tooltip: {
      theme: 'dark'
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let RevenueSeriesData = [], CollectionSeriesData = [], OutstandingSeriesData = []
    let MalePatientsSeriesData = [], FemalePatientsSeriesData = [], TotalPatientsSeriesData = []

    apiData !== null && apiData.forEach(md => {
      let totalMonthlyRev = 0
      let totalMonthlyCollection = 0
      let totalMonthlyOutstandings = 0

      let malePatients = md.patientCount.Male ? md.patientCount.Male : 0
      let femalePatients = md.patientCount.Female ? md.patientCount.Female : 0
      let totalPatients = malePatients + femalePatients

      md.revenues.forEach(mrev => {
        if (mrev == null) return
        totalMonthlyRev += Object.values(mrev)[0]
      })

      if (md.outstandings && md.outstandings.length > 0) {
        md.outstandings.forEach(out => {
          totalMonthlyOutstandings += Object.values(out)[0]
        })
      }

      md.payments.forEach(mPayment => {
        totalMonthlyCollection += Object.values(mPayment)[0]
      })

      RevenueSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": totalMonthlyRev
      })

      CollectionSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": totalMonthlyCollection
      })

      OutstandingSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": totalMonthlyOutstandings
      })

      MalePatientsSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": malePatients
      })
      FemalePatientsSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": femalePatients
      })

      TotalPatientsSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": totalPatients
      })

    })

    return {
      FinancialSeries: [
        {
          name: "Revenue",
          data: RevenueSeriesData,
          type: 'line'
        },
        {
          name: "Collection",
          data: CollectionSeriesData,
          type: 'line'
        },
        {
          name: "Outstanding",
          data: OutstandingSeriesData,
          type: 'line'
        }
      ],
      PatientSeries: [
        {
          name: "Patients",
          data: TotalPatientsSeriesData,
          type: 'line'
        },
        {
          name: "Males",
          data: MalePatientsSeriesData,
          type: 'line'
        }, {
          name: "Females",
          data: FemalePatientsSeriesData,
          type: 'line'
        }
      ]
    }
  }

  return (
    <>
      <div id="financial-chart">
        <br />
        <Chart options={optionsFinancial} series={getSeriesData().FinancialSeries} type="line" height={400} />
      </div>
      <div id="patient-chart">
        <br />
        <Chart options={optionsPatient} series={getSeriesData().PatientSeries} type="line" height={200} />
        <br />
      </div>
    </>
  )
}

export default MonthlyOverviewChart
