import React from 'react'
import { Table } from 'antd'
// import moment from 'moment'

const DailyFinancialOverviewTable = ({ apiData, currencyInfo }) => {
  console.log("isssssssssssssss")
  const getTableCols = () => {
    let Cols = []

    Cols.push({
      title: 'Head',
      dataIndex: 'head',
      key: 'head',
      // width: 120,
      width: window.innerWidth < 768 ? 90 : 120,
      fixed: window.innerWidth < 768 ? '' : 'left',
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
      // fixed: 'left'
    })

    apiData && apiData.length > 0 && apiData.forEach(dd => {
      Cols.push({
        title: dd['trandate'],
        key: `head-${dd['trandate']}`,
        dataIndex: `head-${dd['trandate']}`,
        // width: 160,
        width: window.innerWidth < 768 ? 110 : 160,

        align: 'right',
        render: ((text, rec, idx) => {
          return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
            {
              currencyInfo + new Intl.NumberFormat('en-IN').format(text)
            }
            {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
          </span>)
        })
      })
    })

    Cols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      // fixed: 'right',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      // width: 160,
      width: window.innerWidth < 768 ? 120 : 160,

      render: ((text, rec, idx) => {
        return (<span style={{ fontWeight: 'bold', fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
          {
            currencyInfo + new Intl.NumberFormat('en-IN').format(text)
          }
          {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
        </span>)
      })
    })

    return Cols
  }

  const getTableData = () => {
    let tableDataArray = []
    tableDataArray[0] = {} // rev total
    tableDataArray[1] = {} // collection total
    tableDataArray[2] = {} // outstanding total
    // tableDataArray[3] = {} // daily consolidated total

    tableDataArray[0]['head'] = <span style={{ color: "#3399FF", fontWeight: 'bold' }}>Revenue</span>
    tableDataArray[1]['head'] = <span style={{ color: "#00E396", fontWeight: 'bold' }}>Collection</span>
    tableDataArray[2]['head'] = <span style={{ color: "#F15B46", fontWeight: 'bold' }}>Outstanding</span>
    // tableDataArray[3]['head'] = <span style={{ fontWeight: 'bold' }}>Total</span>

    tableDataArray[0]['total'] = 0
    tableDataArray[1]['total'] = 0
    tableDataArray[2]['total'] = 0
    // tableDataArray[3]['total'] = 0

    apiData && apiData.length > 0 && apiData.forEach(d => {
      let currDate = d.trandate
      let runningRevTotal = 0
      let runningCollectionTotal = 0
      let runningOutstandingTotal = 0
      let runningDailyTotal = 0

      d.revenues.forEach(rev => {
        runningRevTotal += Object.values(rev)[0]
      })

      d.payments.forEach(pay => {
        runningCollectionTotal += Object.values(pay)[0]
      })

      d.outstandings.forEach(outs => {
        runningOutstandingTotal += Object.values(outs)[0]
      })

      // runningDailyTotal = runningRevTotal + runningCollectionTotal + runningOutstandingTotal

      tableDataArray[0][`head-${currDate}`] = runningRevTotal
      tableDataArray[1][`head-${currDate}`] = runningCollectionTotal
      tableDataArray[2][`head-${currDate}`] = runningOutstandingTotal
      // tableDataArray[3][`head-${currDate}`] = runningDailyTotal

      tableDataArray[0]['total'] += runningRevTotal
      tableDataArray[1]['total'] += runningCollectionTotal
      tableDataArray[2]['total'] += runningOutstandingTotal
      // tableDataArray[3]['total'] += runningDailyTotal
    })

    return tableDataArray
  }

  return (
    <>
      <Table bordered columns={getTableCols()} dataSource={getTableData()}
        pagination={false} size="small"
        // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
        style={{ maxWidth: 'calc(100vw - 24px)' }}
        scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
      />
      {
        console.log(<Table bordered columns={getTableCols()} dataSource={getTableData()}
          pagination={false} size="small"
          // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
          style={{ maxWidth: 'calc(100vw - 24px)' }}
          scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
        />, "loadddddddddddd")
      }
    </>
  )
}

export default DailyFinancialOverviewTable