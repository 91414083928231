import React from 'react'
import * as AppStaticData from '../data/AppStaticData'

const getOverviewChartData = (apiData) => {

  let TotalRevenue = []
  let TotalCollection = []
  let TotalOutstanding = []
  let Males = []
  let Females = []
  let TotalPatients = []

  // getOverviewChartDataInput.forEach(dd => {
  apiData !== null && apiData.forEach(dd => {
    let totalDailyRev = 0
    let totalDailyCollection = 0
    let totalDailyOutstandings = 0

    let malePatients = dd.patientCount.Male ? dd.patientCount.Male : 0
    let femalePatients = dd.patientCount.Female ? dd.patientCount.Female : 0
    let totalPatients = malePatients + femalePatients

    dd.revenues.forEach(drev => {
      if (drev == null) return
      totalDailyRev += Object.values(drev)[0]
    })

    if (dd.outstandings.length > 0) {
      dd.outstandings.forEach(out => {
        totalDailyOutstandings += Object.values(out)[0]
      })
    }

    dd.payments.forEach(dPayment => {
      totalDailyCollection += Object.values(dPayment)[0]
    })

    TotalRevenue.push({
      "x": dd.trandate,
      "y": totalDailyRev
    })
    TotalCollection.push({
      "x": dd.trandate,
      "y": totalDailyCollection
    })
    TotalOutstanding.push({
      "x": dd.trandate,
      "y": totalDailyOutstandings
    })

    Males.push({
      "x": dd.trandate,
      "y": malePatients
    })
    Females.push({
      "x": dd.trandate,
      "y": femalePatients
    })

    TotalPatients.push({
      "x": dd.trandate,
      "y": totalPatients
    })

  })

  const retObj = {
    "Financial": {
      TotalRevenue,
      TotalCollection,
      TotalOutstanding,
    },
    "Patients": {
      Males,
      Females,
      TotalPatients,
    }
  }
  return retObj
}

const getRevenueTableData = (apiData) => {

  const getTableCols = (apiData) => {
    const retCols = []

    retCols.push({
      title: 'Dept',
      dataIndex: 'dept',
      key: 'dept',
      fixed: 'left',
      width: 160
    })

    apiData.forEach(dailyData => {
      retCols.push({
        title: dailyData['trandate'],
        key: `revenue-${dailyData['trandate']}`,
        dataIndex: `revenue-${dailyData['trandate']}`,
        width: 120,
        align: 'right',
        render: ((text, rec, idx) => {
          let fontWeight = 'inherit'
          if (!rec.key.includes('subDept')) fontWeight = 'bold'
          return (<span style={{ fontWeight }}>989898</span>)
          // return (<span style={{ fontWeight }}>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(text)}</span>)
        }),

        // children: [
        //   {
        //     title: 'Revenue',
        //     dataIndex: `revenue-${dailyData['mmyy']}`,
        //     key: `revenue-${dailyData['mmyy']}`,
        //     render: (text => <span style={{ fontWeight: 'bold' }}>{text}</span>)
        //   },
        // {
        //   title: '% of Monthly',
        //   dataIndex: `percentMonthly-${dailyData['mmyy']}`,
        //   key: `percentMonthly-${dailyData['mmyy']}`,
        //   width: 100
        // },
        // {
        //   title: 'cf. previous month(%)',
        //   dataIndex: `lastMonth-${dailyData['mmyy']}`,
        //   key: `lastMonth-${dailyData['mmyy']}`,
        //   width: 100,
        //   render: (text => {
        //     if (parseFloat(text) > 0) {
        //       return (<><CaretUpOutlined style={{ color: 'green' }} /><span style={{ color: 'green' }}>{text}</span></>)
        //     } else if (parseFloat(text) < 0) {
        //       return (<><CaretDownOutlined style={{ color: 'red' }} /><span style={{ color: 'red' }}>{text}</span></>)
        //     }
        //     return (<span>0.00</span>)
        //   })
        // }
        // ]
      })
    })
    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      fixed: 'right',
      align: 'right',
      width: 120
    })

    return retCols
  }

  const getTableData = (apiData) => {
    let tableDataArray = []
    let insertedDeptList = []
    insertedDeptList[0] = []
    insertedDeptList[1] = []
    apiData.forEach((d, dIdx) => {
      let currDate = d.trandate

      d.revenues.forEach(rev => {

        if (rev == null) return

        let deptName = Object.keys(rev)[0]
        let deptRev = Object.values(rev)[0]

        AppStaticData.deptsSubDepts.forEach(headerDept => {
          if (deptName == headerDept.dept.key) {
            let rowObj = {}
            if (insertedDeptList[0].indexOf(deptName) < 0) {
              insertedDeptList[0].push(headerDept.dept.key)
              insertedDeptList[1].push([])
              rowObj['key'] = headerDept.dept.key
              rowObj['dept'] = headerDept.dept.display
              rowObj['children'] = []
            } else {
              rowObj = tableDataArray[insertedDeptList[0].indexOf(deptName)]
            }
            rowObj[`revenue-${currDate}`] = deptRev

            let insertedDeptListIdx = insertedDeptList[0].indexOf(deptName)

            rev.subDepts.forEach(sdRev => {
              let subDeptName = Object.keys(sdRev)[0]
              let subDeptRev = Object.values(sdRev)[0]
              headerDept.subDepts.forEach(subDept => {
                let childRowObj = {}
                if (subDept.key == subDeptName) {

                  if (insertedDeptList[1][insertedDeptListIdx].indexOf(subDeptName) < 0) {
                    insertedDeptList[1][insertedDeptListIdx].push(subDept.key)
                    childRowObj['key'] = `subDept-${subDept.key}`
                    childRowObj['dept'] = subDept.display
                    childRowObj[`revenue-${currDate}`] = subDeptRev
                    rowObj['children'].push(childRowObj)
                  } else {
                    childRowObj = rowObj['children'][insertedDeptList[1][insertedDeptListIdx].indexOf(subDeptName)]
                    childRowObj[`revenue-${currDate}`] = subDeptRev
                    rowObj['children'][insertedDeptList[1][insertedDeptListIdx]] = childRowObj
                  }
                }
              })
            })
            tableDataArray[insertedDeptList[0].indexOf(deptName)] = rowObj
          }
        })
      })
    })
    // console.log(JSON.stringify(tableDataArray))
    return tableDataArray
  }

  const retObj = {
    ColsData: getTableCols(apiData),
    TableData: getTableData(apiData)
  }

  return retObj

}

const getCollectionsTableData = (apiData) => {

  const getTableCols = apiData => {
    let retCols = []

    retCols.push({
      title: 'Mode',
      key: 'mode',
      dataIndex: 'mode',
      fixed: 'left',
      width: 120
    })

    apiData.forEach(dd => {
      retCols.push({
        title: dd['trandate'],
        key: `collection-${dd['trandate']}`,
        dataIndex: `collection-${dd['trandate']}`,
        width: 120,
        align: 'right'
      })
    })

    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      fixed: 'right',
      align: 'right',
      width: 120
    })
    return retCols
  }

  const getTableData = apiData => {
    let tableDataArray = []
    let insertedModeList = []
    //insertedModeList[0] is array of pay modes
    insertedModeList[0] = []
    //insertedModeList[1] is running total amount against pay mode at insertedModeList[0]
    insertedModeList[1] = []
    let daywiseTotalRowObj = {
      'key': 'total',
      'mode': 'Total',
    }
    apiData.forEach((d, dIdx) => {
      let currDate = d.trandate
      let daywiseTotal = 0
      d.payments.forEach(p => {
        let payMode = Object.keys(p)[0]
        let payAmt = Object.values(p)[0]
        let rowObj = {}
        if (insertedModeList[0].indexOf(payMode) < 0) {
          insertedModeList[0].push(payMode)
          rowObj['key'] = payMode
          rowObj['mode'] = payMode
          insertedModeList[1][insertedModeList[0].indexOf(payMode)] = payAmt
        } else {
          rowObj = tableDataArray[insertedModeList[0].indexOf(payMode)]
          insertedModeList[1][insertedModeList[0].indexOf(payMode)] += payAmt
        }
        rowObj[`collection-${currDate}`] = payAmt
        tableDataArray[insertedModeList[0].indexOf(payMode)] = rowObj
        daywiseTotal += payAmt
      })
      daywiseTotalRowObj[`collection-${currDate}`] = daywiseTotal
    })

    tableDataArray.push(daywiseTotalRowObj)

    tableDataArray.forEach((t, tIdx) => {
      let tKeysList = Object.keys(t)
      apiData.forEach(ad => {
        if (!tKeysList.includes(`collection-${ad.trandate}`)) {
          tableDataArray[tIdx][`collection-${ad.trandate}`] = 0
        }
      })
      tableDataArray[tIdx]['total'] = insertedModeList[1][tIdx]
      if (tableDataArray.length == tIdx + 1) {
        tableDataArray[tIdx]['total'] = insertedModeList[1].reduce((a, b) => a + b, 0)
      }
    })

    return tableDataArray
  }

  const retObj = {
    ColsData: getTableCols(apiData),
    TableData: getTableData(apiData)
  }

  return retObj
}

const getPatientsTableData = (apiData) => {

  const getTableCols = apiData => {
    const retCols = []

    retCols.push({
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender',
      fixed: 'left',
      width: 160
    })

    apiData.forEach(dailyData => {
      retCols.push({
        title: dailyData['trandate'],
        key: `gender-${dailyData['trandate']}`,
        dataIndex: `gender-${dailyData['trandate']}`,
        width: 80,
        align: 'right',
      })
    })

    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      fixed: 'right',
      align: 'right',
      width: 160
    })

    return retCols
  }

  const getTableData = apiData => {
    let tableDataArray = []
    let totalMales = 0
    let totalFemales = 0
    // tableDataArray[0], tableDataArray[1], tableDataArray[2] = {}
    tableDataArray[0] = {
      'key': 'Male',
      'gender': 'Male'
    }
    tableDataArray[1] = {
      'key': 'Female',
      'gender': 'Female'
    }
    tableDataArray[2] = {
      'key': 'Total',
      'gender': 'Total'
    }
    apiData.forEach(dd => {
      console.log(dd, "apiiiiiiiiiiiiii")
      let currDate = dd.trandate
      let malePatients = dd.patientCount.Male ? dd.patientCount.Male : 0
      let femalePatients = dd.patientCount.Female ? dd.patientCount.Female : 0
      let totalPatients = malePatients + femalePatients
      tableDataArray[0][`gender-${currDate}`] = malePatients
      tableDataArray[1][`gender-${currDate}`] = femalePatients
      tableDataArray[2][`gender-${currDate}`] = totalPatients

      totalMales += malePatients
      totalFemales += femalePatients
    })

    tableDataArray[0]['total'] = totalMales
    tableDataArray[1][`total`] = totalFemales
    tableDataArray[2][`total`] = totalMales + totalFemales

    console.log(tableDataArray, "tttttttttttbl")
    return tableDataArray
  }

  const retObj = {
    ColsData: getTableCols(apiData),
    TableData: getTableData(apiData)
  }

  return retObj
}

const getXformedDailyData = (apiData => {

  const OverviewChartData = getOverviewChartData(apiData)
  const RevenueTableData = getRevenueTableData(apiData)
  const CollectionsTableData = getCollectionsTableData(apiData)
  const PatientsTableData = getPatientsTableData(apiData)

  const XformedDailyData = {
    OverviewChartData: {
      Financial: {
        TotalRevenue: OverviewChartData.Financial.TotalRevenue,
        TotalCollection: OverviewChartData.Financial.TotalCollection,
        TotalOutstanding: OverviewChartData.Financial.TotalOutstanding,
      },
      Patients: {
        Males: OverviewChartData.Patients.Males,
        Females: OverviewChartData.Patients.Females,
        Total: OverviewChartData.Patients.TotalPatients
      }
    },
    CollectionsTableData: {
      ColsData: CollectionsTableData.ColsData,
      TableData: CollectionsTableData.TableData
    },
    RevenueTableData: {
      ColsData: RevenueTableData.ColsData,
      TableData: RevenueTableData.TableData
    },
    PatientsTableData: {
      ColsData: PatientsTableData.ColsData,
      TableData: PatientsTableData.TableData
    }
  }

  return XformedDailyData
})

const getXformedDailyDataString = (apiData) => {
  console.log(JSON.stringify(getXformedDailyData(apiData)), "fnnnnnnnnnnnnn")
  return JSON.stringify(getXformedDailyData(apiData))
}

export default getXformedDailyDataString