import React from 'react'
import { Table } from 'antd'

const DailyCollectionsTable = ({ data, apiData, currencyInfo }) => {

  const getTableCols = () => {
    let retCols = []

    retCols.push({
      title: 'Mode',
      key: 'mode',
      dataIndex: 'mode',
      // fixed: 'left',
      fixed: window.innerWidth < 768 ? '' : 'left',
      // width: 120
      width: window.innerWidth < 768 ? 90 : 120,
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData && apiData.length > 0 && apiData.forEach(dd => {
      retCols.push({
        title: dd['trandate'],
        key: `collection-${dd['trandate']}`,
        dataIndex: `collection-${dd['trandate']}`,
        // width: 160,
        width: window.innerWidth < 768 ? 130 : 160,
        align: 'right',
        render: ((text, rec, idx) => {
          return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }} >
            {
              currencyInfo + new Intl.NumberFormat('en-IN').format(text)
            }
            {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
          </span>)
        }),
      })
    })

    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      // fixed: 'right',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      // width: 160,
      width: window.innerWidth < 768 ? 130 : 160,
      render: ((text, rec, idx) => {
        return (<span style={{ fontWeight: 'bold', fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>{
          // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)
          currencyInfo + new Intl.NumberFormat('en-IN').format(text)
        }</span>)
      }),
    })
    return retCols
  }

  const getTableData = () => {
    let tableDataArray = []
    let insertedModeList = []
    //insertedModeList[0] is array of pay modes
    insertedModeList[0] = []
    //insertedModeList[1] is running total amount against pay mode at insertedModeList[0]
    insertedModeList[1] = []
    let daywiseTotalRowObj = {
      'key': 'total',
      'mode': 'Total',
    }
    apiData && apiData.length > 0 && apiData.forEach((d, dIdx) => {
      let currDate = d.trandate
      let daywiseTotal = 0
      d.payments.forEach(p => {
        let payMode = Object.keys(p)[0]
        let payAmt = Object.values(p)[0]
        let rowObj = {}
        if (insertedModeList[0].indexOf(payMode) < 0) {
          insertedModeList[0].push(payMode)
          rowObj['key'] = payMode
          rowObj['mode'] = payMode
          insertedModeList[1][insertedModeList[0].indexOf(payMode)] = payAmt
        } else {
          rowObj = tableDataArray[insertedModeList[0].indexOf(payMode)]
          insertedModeList[1][insertedModeList[0].indexOf(payMode)] += payAmt
        }
        rowObj[`collection-${currDate}`] = payAmt
        tableDataArray[insertedModeList[0].indexOf(payMode)] = rowObj
        daywiseTotal += payAmt
      })
      daywiseTotalRowObj[`collection-${currDate}`] = daywiseTotal
    })

    tableDataArray.push(daywiseTotalRowObj)

    tableDataArray.forEach((t, tIdx) => {

      let tKeysList = Object.keys(t)
      apiData && apiData.length > 0 && apiData.forEach(ad => {
        if (!tKeysList.includes(`collection-${ad.trandate}`)) {
          tableDataArray[tIdx][`collection-${ad.trandate}`] = 0
        }
      })

      tableDataArray[tIdx]['total'] = insertedModeList[1][tIdx]
      if (tableDataArray.length == tIdx + 1) {
        tableDataArray[tIdx]['total'] = insertedModeList[1].reduce((a, b) => a + b, 0)
      }
    })
    // console.log(tableDataArray)
    return tableDataArray
  }

  return (
    <Table bordered columns={getTableCols()} dataSource={getTableData()}
      pagination={false} size="small"
      // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
      style={{ maxWidth: 'calc(100vw - 24px)' }}
      scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
    />
  )
}

export default DailyCollectionsTable