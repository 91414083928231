import React from 'react'
import Chart from "react-apexcharts";

const ArpbChartDash = () => {

  const series = () => {
    return [
      {
        name: "Suite",
        data: [20800, 20900, 30300, 30600, 30200, 30200, 30300]
      },
      {
        name: "Deluxe",
        data: [20600, 20700, 20900, 30100, 20800, 20600, 20900]
      },
      {
        name: "General",
        data: [10400, 10600, 10300, 10700, 10200, 10600, 10900]
      }
    ]
  }

  const options = () => {
    return {
      chart: {
        height: 360,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA', '#545454', '#cf1322'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Average Revenue by Bed',
        align: 'left'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ['15-Jan', '16-Jan', '17-Jan', '18-Jan', '19-Jan', '20-Jan', '21-Jan'],
        title: {
          text: 'Date'
        }
      },
      yaxis: {
        title: {
          text: 'Revenue'
        }
      },
      // legend: {
      //   position: 'bottom',
      //   horizontalAlign: 'center',
      //   floating: true,
      //   offsetY: -25,
      //   offsetX: -5
      // }
    }
  }

  return (
    <div id="wrapper">
      <div id="chart-line">
        <Chart options={options()} series={series()} type="line" height={380} />
      </div>
    </div>
  );

}

export default ArpbChartDash