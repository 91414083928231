import React from 'react'
import Chart from 'react-apexcharts'

const DailyCollectionsChart = ({ apiData, data, currencyInfo }) => {
  const ParsedOverviewChartData = JSON.parse(data).OverviewChartData

  const getTotalCollectionOptions = (() => {
    return ({
      chart: {
        type: 'area',
        height: 300,
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: 'Consolidated Collections',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: (() => { return ParsedOverviewChartData.Financial.TotalCollection.map(e => e.x) })(),
        type: 'datetime',
      },
      yaxis: {
        seriesName: 'Collections',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#00E396'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)
            //  new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value)

          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        },
        tooltip: {
          enabled: false
        },
        crosshairs: {
          show: false,
        }
      },
      tooltip: {
        theme: 'dark'
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
      }
    })
  })

  const getTotalCollectionSeries = (() => {
    return ([{
      name: "Collections",
      data: (() => { return ParsedOverviewChartData.Financial.TotalCollection.map(e => e.y) })(),
      type: 'area'
    }])
  })

  return (
    <>
      <Chart options={getTotalCollectionOptions()} series={getTotalCollectionSeries()} type="area" height={300} />
      <br />
      {/* <Chart options={getSubDeptsRevOptions()} series={getSubDeptsRevSeries()} type="line" height={400} /> */}
    </>
  )
}

export default DailyCollectionsChart