import React from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import GetApiData from '../utils/GetApiData';
const MonthlyExpensesTable = ({ apiData, currencyInfo, apiHost }) => {


  const getTableCols = () => {
    let retCols = []

    retCols.push({
      title: 'Ledger',
      key: 'head',
      dataIndex: 'head',
      fixed: window.innerWidth < 768 ? '' : 'left',
      // width: 120
      width: window.innerWidth < 768 ? 90 : 120,
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData && apiData.length > 0 && apiData.forEach(md => {
      retCols.push({
        title: moment(md.trandate).format("MMM-YYYY"),
        title: md['trandate'],
        key: md['trandate'],
        dataIndex: md['trandate'],
        // width: 160,
        width: window.innerWidth < 768 ? 100 : 160,
        align: 'right',
        render: ((text, rec, idx) => {
          return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>{
            currencyInfo + new Intl.NumberFormat('en-IN').format(text)
            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)
          }</span>)
        }),
      })
    })

    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      // fixed: 'right',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      // width: 160,
      width: window.innerWidth < 768 ? 100 : 160,
      render: ((text, rec, idx) => {
        return (<span style={{ fontWeight: 'bold', fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>{
          // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)
          currencyInfo + new Intl.NumberFormat('en-IN').format(text)
        }</span>)
      })
    })

    return retCols
  }

  const getTableData = () => {
    let tableDataArray = []
    let insertedHeadList = []
    //insertedHeadList[0] is array of expense ledger type
    insertedHeadList[0] = []
    //insertedHeadList[1] is running total against expense ledger type at insertedHeadList[0]
    insertedHeadList[1] = []

    let daywiseTotalRowObj = {
      'key': 'total',
      'head': 'Total'
    }

    apiData && apiData.length > 0 && apiData.forEach((d, dIdx) => {
      let currDate = d.trandate
      let daywiseTotal = 0
      d.expenses && d.expenses.length > 0 && d.expenses[0].ledgers && d.expenses[0].ledgers.forEach(ledger => {
        let ledgerMode = Object.keys(ledger)[0]
        let ledgerAmt = Object.values(ledger)[0]

        let rowObj = {}
        if (insertedHeadList[0].indexOf(ledgerMode) < 0) {
          insertedHeadList[0].push(ledgerMode)
          rowObj['key'] = ledgerMode
          rowObj['head'] = ledgerMode
          insertedHeadList[1][insertedHeadList[0].indexOf(ledgerMode)] = ledgerAmt
        } else {
          rowObj = tableDataArray[insertedHeadList[0].indexOf(ledgerMode)]
          insertedHeadList[1][insertedHeadList[0].indexOf(ledgerMode)] += ledgerAmt
        }
        rowObj[`${currDate}`] = ledgerAmt
        tableDataArray[insertedHeadList[0].indexOf(ledgerMode)] = rowObj
        daywiseTotal += ledgerAmt
      })
      daywiseTotalRowObj[`${currDate}`] = daywiseTotal
    })

    tableDataArray.push(daywiseTotalRowObj)

    tableDataArray.forEach((t, tIdx) => {
      let tKeysList = Object.keys(t)
      apiData && apiData.length > 0 && apiData.forEach(ad => {
        if (!tKeysList.includes(`${ad.trandate}`)) {
          tableDataArray[tIdx][`${ad.trandate}`] = 0
        }
      })

      tableDataArray[tIdx]['total'] = insertedHeadList[1][tIdx]
      if (tableDataArray.length == tIdx + 1) {
        tableDataArray[tIdx]['total'] = insertedHeadList[1].reduce((a, b) => a + b, 0)
      }
    })
    return tableDataArray
  }
  const fnDownloadReport = (pdfAction) => {

    let FromDate = apiData[0].trandate + "-01"
    let ToDate = apiData[apiData.length - 1].trandate.split("-");
    let _ToDate_var = new Date(ToDate[0], ToDate[1], 0);

    ToDate = moment(_ToDate_var).format("YYYY-MM-DD")


    let organization_id = localStorage.getItem("organisationId");
    let unit_id = apiData[0].unitId;
    GetApiData.downloadExpenseReport({
      FromDate,
      ToDate,
      organization_id,
      unit_id,
      apiHost, pdfAction
    })
      .then(data => {
        if (pdfAction == "view") {
          let pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>")

        }
      }).catch(err => {
        console.log("eeeeeeeee", err)
      })
  }
  return (
    <>
      <Table bordered columns={getTableCols()} dataSource={getTableData()}
        pagination={false} size="small"
        // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
        style={{ maxWidth: 'calc(100vw - 24px)' }}
        scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
      />
      {
        apiData && apiData.length > 0 &&
        <>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold'
          }} onClick={() => {
            fnDownloadReport("view")
          }}>
            <FilePdfOutlined /> View  PDF
        </div>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold', clear: "both"
          }} onClick={() => {
            fnDownloadReport("download")
            // fnDownloadDemo()
          }}>
            <DownloadOutlined /> Download  PDF

        </div>
        </>
      }
    </>
  )
}

export default MonthlyExpensesTable