import React from 'react'
import Chart from 'react-apexcharts'

const RevenueChart = ({ _revenue, _collections, _outstanding }) => {

  const revenue = _revenue.toFixed(2)
  const collections = _collections.toFixed(2)
  const outstanding = _outstanding.toFixed(2)

  const getSeries = () => {

    return [{
      name: 'Revenue',
      data: [revenue]
    }, {
      name: 'Collections',

      data: [collections]
    },
    {
      name: 'Outstanding',

      data: [outstanding]
    }
    ]
  }

  const getOptions = () => {
    return {
      chart: {
        type: 'bar',
        height: 320
      },
      title: {
        text: 'Financial'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: ['Revenue', 'Collections', 'Outstanding'],
      },
    }
  }

  return < Chart options={getOptions()} series={getSeries()} type="bar" height={320} />
}

export default RevenueChart