import React from 'react'
import { Tabs } from 'antd';
import Chart from "react-apexcharts"

class DebtorChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        data: [400, 430, 448, 1200, 1380]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['Eco Focus', 'Strat Corp.', 'Sanguine', 'Sporadic Sys', 'Ferrets Pvt'],
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
          text: 'Debtors',
          align: 'center',
          floating: true
        },
        subtitle: {
          text: 'Figures in thousands',
          align: 'center',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      },


    };
  }



  render() {
    return (


      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="bar" height={380} />
      </div>


    );
  }
}

class CreditChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        data: [309, 412, 560, 781, 968]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B'],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['Note Tech', 'Hari OM Logistics', 'Modern Furniture', 'ABC Cable', 'Royal Med Pvt'],
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
          text: 'Debtors',
          align: 'center',
          floating: true
        },
        subtitle: {
          text: 'Figures in thousands',
          align: 'center',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      },


    };
  }



  render() {
    return (


      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="bar" height={380} />
      </div>


    );
  }
}

class OverviewChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        name: 'Credit',
        data: [{
          x: "Feb-20",
          y: 322
        },
        {
          x: "March-20",
          y: 324
        },
        {
          x: "April-20",
          y: 329
        },
        {
          x: "May-20",
          y: 342
        },
        {
          x: "June-20",
          y: 348
        },
        {
          x: "July-20",
          y: 334
        },
        {
          x: "Aug-20",
          y: 325
        },
        {
          x: "Sep-20",
          y: 316
        },
        {
          x: "Oct-20",
          y: 318
        },
        {
          x: "Nov-20",
          y: 330
        },
        {
          x: "Dec-20",
          y: 355
        }
        ]
      }, {
        name: 'Debt',
        data: [
          {
            x: "Feb-20",
            y: -162
          },
          {
            x: "March-20",
            y: -90
          },
          {
            x: "April-20",
            y: -50
          },
          {
            x: "May-20",
            y: -77
          },
          {
            x: "June-20",
            y: -35
          },
          {
            x: "July-20",
            y: -45
          },
          {
            x: "Aug-20",
            y: -88
          },
          {
            x: "Sep-20",
            y: -120
          },
          {
            x: "Oct-20",
            y: -156
          },
          {
            x: "Nov-20",
            y: -123
          },
          {
            x: "Dec-20",
            y: -88
          }
        ]
      }],
      options: {
        chart: {
          type: 'area',
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        colors: ["#008000", "#B03060"],
        title: {
          text: 'Debt & Credit (in thousands)',
          align: 'left',
          style: {
            fontSize: '14px'
          }
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: '#8e8da4',
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "yyyy",
          },
          fixed: {
            enabled: false,
            position: 'topRight'
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      },


    };
  }
  render() {
    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="area" height={350} />
      </div>
    );
  }
}

const { TabPane } = Tabs;
const DebtorCreditor = () => {

  return (
    <Tabs type="card">
      <TabPane tab="Overview" key="1">
        <OverviewChart />
      </TabPane>
      <TabPane tab="Creditors" key="2">
        <CreditChart />
      </TabPane>
      <TabPane tab="Debtors" key="3">
        <DebtorChart />
      </TabPane>
    </Tabs>
  )
}

export default DebtorCreditor