import React from 'react'
import Chart from "react-apexcharts";
import DummyData from "./DummyData"

const getCategs = () => DummyData.Alos.slice(0, 4).map(e => e.spec)

const getLosSeriesData = () => DummyData.Alos.slice(0, 4).map(e => e.los)

const getDischargeSeriesData = () => DummyData.Alos.slice(0, 4).map(e => e.discharges)

const AlosChartDash = () => {

  const getSeriesData = () => {
    return [{
      name: 'Length of Stay',
      type: 'column',
      data: getLosSeriesData()
    }, {
      name: 'Discharges',
      type: 'line',
      data: getDischargeSeriesData()
    }]
  }

  const getOptions = () => {
    return {
      chart: {
        height: 320,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: 'Length of Stay Report'
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      // labels: getCategs(),
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false,
        },
        categories: getCategs()
      },
      yaxis: [{
        title: {
          text: 'Length of Stay (Days)',
        },
      }, {
        opposite: true,
        title: {
          text: 'Discharges'
        }
      }]
    }
  }

  return <Chart options={getOptions()} series={getSeriesData()} type="line" height={380} />

}

export default AlosChartDash