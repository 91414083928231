import React from 'react'
import Chart from 'react-apexcharts'

const DailyDiscountsChart = ({ apiData, currencyInfo }) => {

  const optionsFinancial = {
    chart: {
      id: 'discountsOverview',
      group: 'discountsOverview',
      type: 'line',
      stacked: false,
      height: 600,
      events: {
        mouseMove: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Discounts & Concessions',
      align: 'left',
      offsetX: 110
    },

    colors: ['#008FFB', '#00E396'],
    yaxis: [
      {
        seriesName: 'Discounts',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)
            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value) 
          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        },
        tooltip: {
          theme: "dark"
        }
      }
    ],
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let DiscountSeriesData = [], ConcessionSeriesData = []

    apiData.forEach(dd => {

      DiscountSeriesData.push({
        "x": dd.trandate,
        "y": dd.discount
      })

      ConcessionSeriesData.push({
        "x": dd.trandate,
        "y": dd.concession
      })
    })

    return {
      FinancialSeries: [
        {
          name: "Discounts",
          data: DiscountSeriesData,
          type: 'line'
        },
        {
          name: "Discounts",
          data: ConcessionSeriesData,
          type: 'line'
        }
      ]
    }
  }

  return <Chart options={optionsFinancial} series={getSeriesData().FinancialSeries} type="line" height={600} />

}

export default DailyDiscountsChart