import React from 'react'
import { Table } from 'antd'
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import GetApiData from '../utils/GetApiData';
const DailyOutstandingsTable = ({ data, apiData, currencyInfo, apiHost }) => {
  const getTableCols = () => {
    let retCols = []

    retCols.push({
      title: "Company",
      key: "company",
      dataIndex: "company",
      // fixed: "left",
      fixed: window.innerWidth < 768 ? '' : 'left',
      // width: 120
      width: window.innerWidth < 768 ? 100 : 120,
      render: (text) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}  >{text}</span>)
      }
    })

    apiData && apiData.length > 0 && apiData.forEach(dd => {
      retCols.push({
        title: dd['trandate'],
        key: `outstanding-${dd['trandate']}`,
        dataIndex: `outstanding-${dd['trandate']}`,
        // width: 160,
        width: window.innerWidth < 768 ? 110 : 160,
        align: 'right',
        render: ((text, rec, idx) => {
          return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
            {
              currencyInfo + new Intl.NumberFormat('en-IN').format(text)
            }
            {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
          </span>)
        }),
      })
    })

    retCols.push({
      title: 'Total',
      key: 'total',
      dataIndex: 'total',
      // fixed: 'right',
      fixed: window.innerWidth < 768 ? '' : 'right',
      align: 'right',
      // width: 160,
      width: window.innerWidth < 768 ? 120 : 160,
      render: ((text, rec, idx) => {
        return (<span style={{ fontSize: window.innerWidth < 768 ? "12px" : "15px" }}>
          {
            currencyInfo + new Intl.NumberFormat('en-IN').format(text)
          }
          {/* {new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(text)} */}
        </span>)

      }),
    })

    return retCols

  }

  const getTableData = () => {
    let tableDataArray = []
    let insertedModeList = []
    //insertedModeList[0] is array of pay modes
    insertedModeList[0] = []
    //insertedModeList[1] is running total amount against pay mode at insertedModeList[0]
    insertedModeList[1] = []
    let daywiseTotalRowObj = {
      'key': 'total',
      'company': 'Total',
    }

    apiData && apiData.length > 0 && apiData.forEach(d => {
      let currDate = d.trandate
      let daywiseTotal = 0
      d.outstandings.forEach(o => {
        let outCompany = Object.keys(o)[0]
        let outAmt = Object.values(o)[0]
        let rowObj = {}

        if (insertedModeList[0].indexOf(outCompany) < 0) {
          insertedModeList[0].push(outCompany)
          rowObj['key'] = outCompany
          rowObj['company'] = outCompany
          insertedModeList[1][insertedModeList[0].indexOf(outCompany)] = outAmt
        } else {
          rowObj = tableDataArray[insertedModeList[0].indexOf(outCompany)]
          insertedModeList[1][insertedModeList[0].indexOf(outCompany)] += outAmt
        }

        rowObj[`outstanding-${currDate}`] = outAmt
        tableDataArray[insertedModeList[0].indexOf(outCompany)] = rowObj
        daywiseTotal += outAmt
      })
      daywiseTotalRowObj[`outstanding-${currDate}`] = daywiseTotal
    })

    tableDataArray.push(daywiseTotalRowObj)

    tableDataArray.forEach((t, tIdx) => {
      let tKeysList = Object.keys(t)
      apiData && apiData.length > 0 && apiData.forEach(ad => {
        if (!tKeysList.includes(`outstanding-${ad.trandate}`)) {
          tableDataArray[tIdx][`outstanding-${ad.trandate}`] = 0
        }
      })

      tableDataArray[tIdx]['total'] = insertedModeList[1][tIdx]

      if (tableDataArray.length == tIdx + 1) {
        tableDataArray[tIdx]['total'] = insertedModeList[1].reduce((a, b) => a + b, 0)
      }
    })
    // console.log(tableDataArray)
    return tableDataArray
  }
  const fnDownloadReport = (pdfAction) => {
    console.log(apiHost, "hosttttttttt")
    let FromDate = apiData[0].trandate
    let ToDate = apiData[apiData.length - 1].trandate;
    let organization_id = localStorage.getItem("organisationId");
    let unit_id = apiData[0].unitId;
    GetApiData.downloadOutstandingReport({
      FromDate,
      ToDate,
      organization_id,
      unit_id,
      apiHost, pdfAction
    })
      .then(data => {
        if (pdfAction == "view") {
          let pdfWindow = window.open("")
          pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(data) + "'></iframe>")

        }

      }).catch(err => {
        console.log("eeeeeeeee", err)
      })
  }
  return (
    <>
      <Table bordered columns={getTableCols()} dataSource={getTableData()}
        pagination={false} size="small"
        // style={{ maxWidth: 'calc(100vw - 24px)', maxHeight: 'calc(100vh - 230px)' }}
        style={{ maxWidth: 'calc(100vw - 24px)' }}
        scroll={{ x: 'calc(100vw - 200px)', y: window.innerWidth <= 768 ? 'calc(100vh - 27px)' : 'calc(100vh - 270px)' }}
      />
      {
        apiData && apiData.length > 0 &&
        <>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold'
          }} onClick={() => {
            fnDownloadReport("view")
          }}>
            <FilePdfOutlined /> View  PDF
        </div>
          <div style={{
            width: "128px",
            float: "right",
            padding: "5px",
            cursor: "pointer", fontWeight: 'bold', clear: "both"
          }} onClick={() => {
            fnDownloadReport("download")

          }}>
            <DownloadOutlined /> Download  PDF

        </div>
        </>
      }
    </>
  )
}

export default DailyOutstandingsTable