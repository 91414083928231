import React from 'react';
import { Form, Input, Button, Checkbox, Card, Modal, Select } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import LoadingSvg from '../Layout/LoadingSvg'
import "./Login.css";
import GetApiData from "../utils/GetApiData";
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            confirmLoading: false,
            login: false,
            svgVisible: false,
            userName: 'superadmin',
            password: '12345',
            errorMsg: '',
            organisationList: this.props.organisationList,
            defaultOrganisationNAme: '',
            defaultOrgName: this.props.defaultOrgName,
            orgID: '',
            unitList: [],
            selectedUnitId: '',
            isSuccess: false,
            apiHost: this.props.apiHost,
            isLogout: this.props.isLogout,
            currency: ""
        }
    }
    componentDidMount() {
        console.log(localStorage.getItem("organisationId"), "1111111111111")
        if (this.props.isLogout) {
            setTimeout(() => {
                this.setState({
                    isLogout: false
                })
            }, 1000)
        }
    }
    componentDidUpdate(preProps, prevState) {
        if (this.props !== preProps) {

            this.setState({
                apiHost: this.props.apiHost
            })
            if (this.props.organisationList.length == 1) {
                this.setState({
                    orgID: this.props.organisationList[0].organizationId
                })
                localStorage.setItem("organisationId", this.state.orgID)
            }
            this.setState({
                organisationList: this.props.organisationList,

            }, async () => {

                let apiHost = this.props.apiHost;
                if (this.state.organisationList.length == 1) {
                    let orgID = this.state.organisationList[0].organizationId;

                    let unitsListApi = await GetApiData.getUnits({
                        orgID,
                        apiHost
                    })
                    this.setState({
                        unitList: unitsListApi
                    })
                }
            })
        }
    }
    showModal = () => {
        this.setState({
            visible: true
        })
    };
    handleOk = (values) => {
        this.setState({
            visible: false
        })
    };
    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            visible: false
        })
    };
    fn_Login = async () => {
        if (this.state.userName == "") {
            this.setState({
                errorMsg: "Please Enter UserName"
            })
            return;
        }
        if (this.state.password == "") {
            this.setState({
                errorMsg: "Please Enter Password"
            })
            return;
        }

        if (this.state.selectedUnitId == "") {
            console.log(this.state.selectedUnitId, "iiiiiiiiiiii")
            this.setState({
                errorMsg: "Please Select Unit"
            })
            return;
        }
        this.props._fngetOrgId(this.props.organizationId, this.state.currency)
        let response = await GetApiData.Login({

            organizationId: this.state.orgID,
            apiHost: this.props.apiHost,
            unitId: this.state.selectedUnitId,
            userName: this.state.userName,
            password: this.state.password,
            macAddress: ""
        })
        if (response && response.status && response.status == "success" && response.message == 'Login Successful') {
            console.log(response, "iiiiiiiirrrr")
            localStorage.setItem("isLogin", "yes")
            this.setState({
                isSuccess: true,
                svgVisible: false
            })

            this.setState({
                isSuccess: true,
                svgVisible: false
            })

            // this.props.changeSelectedKey(true, this.props.organizationId);
            this.props.changeSelectedKey(true);

        }
        if (response && response.status && response.status == "error" && response.message) {
            this.setState({
                errorMsg: response.message
            })
        }

    }

    getUnitList = async () => {
        let organizationId = this.state.orgID;
        let apiHost = this.state.apiHost;
        let unitsListApi = await GetApiData.getUnits({
            organizationId,
            apiHost
        })
        this.setState({
            unitList: unitsListApi
        }, () => {
            console.log(this.state.unitList, "uuuuuuuuuuu")
        })
    }
    render() {
        const { Option } = Select;
        return (
            <>
                <div className="bckImg">
                    <div className="centredClass">
                        <div className="main-heading" ><span>Hospital Information System</span></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="wrapper">

                            <Card bordered={false} style={{ width: 300 }}>

                                <Input placeholder="User Name" suffix={<UserOutlined />}
                                    onChange={(e) => {
                                        this.setState({
                                            userName: e.target.value.trim()
                                        })

                                    }}
                                />
                                <br />
                                <br />
                                <Input placeholder="Password" type="password" suffix={<LockOutlined />}
                                    onChange={(e) => {
                                        this.setState({
                                            password: e.target.value.trim()
                                        })
                                    }}
                                />

                                <br />
                                <br />

                                {
                                    this.state.organisationList.length == 1 ?
                                        <Select size={'middle'} defaultValue={this.props.defaultOrgName}
                                            // placeholder="Select Organisation"
                                            onChange={(val, opt) => {
                                                console.log(JSON.stringify(opt))
                                                localStorage.setItem("organisationId", val)
                                                this.setState({
                                                    orgID: val
                                                })
                                                this.props._fngetOrgId(val)
                                                // setUnitId(val)
                                                // setUnitName(opt.children)
                                            }} style={{ width: '360px' }}>
                                            {this.state.organisationList.map(u => {
                                                return <Option value={u.organizationId} key={u.organizationId}>{u.organizationName}</Option>
                                            })}
                                        </Select>
                                        :
                                        this.state.organisationList.length > 1 ?
                                            <Select size={'middle'}
                                                placeholder="Select Organisation"
                                                onChange={(val, opt) => {
                                                    localStorage.setItem("organisationId", val)
                                                    this.setState({
                                                        orgID: val
                                                    }, () => {
                                                        this.getUnitList()
                                                    })

                                                    // this.props._fngetOrgId(val)
                                                    // setUnitId(val)
                                                    // setUnitName(opt.children)
                                                }} style={{ width: '360px' }}>
                                                {this.state.organisationList.map(u => {
                                                    return <Option value={u.organizationId} key={u.organizationId}>{u.organizationName}</Option>
                                                })}
                                            </Select> : null

                                }

                                <br />
                                <br />

                                {

                                    <Select size={'middle'}
                                        // placeholder="Select Organisation"
                                        onChange={(val, opt) => {
                                            console.log(JSON.stringify(opt))
                                            this.setState({
                                                selectedUnitId: val
                                            }, () => {
                                                let apiHost = this.state.apiHost

                                                let unitId = val
                                                GetApiData.getCurrencyInfo({
                                                    unitId,
                                                    apiHost
                                                }).then((curr) => {

                                                    this.setState({
                                                        currency: curr
                                                    })

                                                }).catch(e => {

                                                })
                                            })

                                        }} style={{ width: '360px' }}>
                                        {this.state.unitList.map(u => {
                                            return <Option value={u.unitId} key={u.unitId}>{u.unitName}</Option>
                                        })}
                                    </Select>


                                }
                                <br /><br />
                                {/* <Checkbox onChange={{}}>Remember me</Checkbox> */}
                                {/* <Button type="link" style={{ float: "right" }} onClick={this.showModal} >Forgot Password</Button> */}
                                <br />
                                <div className="logindiv">
                                    <Button className="Login_btn" onClick={() => {
                                        this.setState({
                                            svgVisible: true
                                        })
                                        this.fn_Login()

                                    }}>Login</Button>
                                </div>
                            </Card>
                            <Modal
                                title="Forgot Password"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                confirmLoading={this.confirmLoading}
                                onCancel={this.handleCancel}
                                width="350px"
                            >
                                <p>
                                    Email ID:
                            </p>
                                <p>
                                    <Input placeholder="Password" suffix={<MailOutlined />} />
                                </p>
                            </Modal>
                            {
                                this.state.svgVisible && this.state.errorMsg == '' &&
                                <div className="svg"><LoadingSvg /></div>

                            }
                            {
                                this.state.isLogout &&
                                <div className="svg"><LoadingSvg /></div>
                            }
                            {
                                this.state.errorMsg !== '' &&
                                <div className="ërrorDiv">
                                    <p className="errorMessage">
                                        {
                                            this.state.errorMsg
                                        }
                                    </p>
                                </div>
                            }
                        </div>,


                    </div>
                </div>
            </>
        )
    }
};
