import React from 'react';
// import { Layout } from "antd";
import { Tabs, Button, Typography, Menu, Dropdown, DatePicker, Select, Layout } from 'antd';
import {
    RiseOutlined, DollarOutlined, FundProjectionScreenOutlined, RedoOutlined,
    CreditCardOutlined, ReconciliationOutlined, TeamOutlined, DollarCircleOutlined,
    ExclamationCircleOutlined, DownOutlined, TableOutlined, BarChartOutlined,
    MinusCircleOutlined, ExperimentOutlined, VideoCameraOutlined,ReloadOutlined 
} from '@ant-design/icons';
import './TopicMenu.css';
const TopicMenu = ({ selectedKey, changeSelectedKey }) => {
    const styledTopics = [];
    // topics.forEach((topic, index) =>
     
    //     styledTopics.push(
    //         <Menu.Item key={index} onClick={changeSelectedKey}>
    //             {topic}
    //         </Menu.Item>
    //     )
    // );
    const menu1 = (
        <>
        <Menu.Item className="logoMenu">
                <div className="Logo" />
        </Menu.Item>
            
         <Menu.Item key={0} icon={<DollarOutlined />} onClick={changeSelectedKey}>
            Overview
           
        </Menu.Item>
        <Menu.Item key={0} >
                Financial
                 
        </Menu.Item>
            
            <Menu.Item className="childMenu subMenuTitle" icon={<DollarOutlined />} >
                <span className="titleName"> Overview</span>
        </Menu.Item> 
         <Menu.Item className="childMenu" key={1} icon={<TableOutlined />} onClick={changeSelectedKey}>
             Table 
        </Menu.Item>
        <Menu.Item className="childMenu" key={2} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
          Chart
       </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<FundProjectionScreenOutlined />}>
                <span> Revenue</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={3} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={4} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<CreditCardOutlined />}>
                <span> Collections</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={5} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={6} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<ExclamationCircleOutlined />}>
                <span> Outstanding</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={7} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={8} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<ReconciliationOutlined />}>
                <span> Discounts</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={9} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={10} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
       {/* financial end */}
       {/* patient start */}
            <Menu.Item key={0} >
                Patient

        </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<TeamOutlined />}>
                <span>  Patient Count</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={11} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={12} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            {/* patient start end*/} 
            {/* expensese start */}
            <Menu.Item key={0}  >
                Expenses

        </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<MinusCircleOutlined />} >
                <span> Expenses</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={13} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={14} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            {/* expensese start end */}
            {/* service status */}
            <Menu.Item key={0} >
                Service Status

        </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<ExperimentOutlined />}>
                <span> Laboratory</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={15} icon={<TableOutlined />}onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={16} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            <Menu.Item className="childMenu subMenuTitle" icon={<VideoCameraOutlined />}>
                <span> Radiology</span>
            </Menu.Item>
            <Menu.Item className="childMenu" key={17} icon={<TableOutlined />} onClick={changeSelectedKey}>
                Table
        </Menu.Item>
            <Menu.Item className="childMenu" key={18} icon={<BarChartOutlined />} onClick={changeSelectedKey}>
                Chart
       </Menu.Item>
            
            {/* service status end */}
        </>
    )
    return (
        // <Menu mode="inline" selectedKeys={[selectedKey]}>
        //     {styledTopics}
        // </Menu>
        <>
          
        <Menu mode="inline" selectedKeys={[selectedKey]}>
            {menu1}
        </Menu>
        </>
    );
}
export default TopicMenu;