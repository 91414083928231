import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import { default as FinalApp } from './Final/App';



import * as serviceWorker from './serviceWorker';
// import DeviceOrientation, { Orientation } from 'react-screen-orientation';
ReactDOM.render(
  <React.StrictMode>
    <FinalApp />
    {/* <Login /> */}
  </React.StrictMode>,
  document.getElementById('root')
);
ReactDOM.render(<FinalApp />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
