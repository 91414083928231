import React from 'react'
import Chart from 'react-apexcharts'

const DailyPatientsChart = ({ data }) => {

  const ParsedOverviewChartData = JSON.parse(data).OverviewChartData

  const chartSeries = [
    {
      name: "Patients",
      data: (() => { return ParsedOverviewChartData.Patients.Total.map(e => e.y) })(),
      type: 'line'
    },
    {
      name: "Males",
      data: (() => { return ParsedOverviewChartData.Patients.Males.map(e => e.y) })(),
      type: 'line'
    }, {
      name: "Females",
      data: (() => { return ParsedOverviewChartData.Patients.Females.map(e => e.y) })(),
      type: 'line'
    }
  ]

  const chartOptions = {
    chart: {
      id: 'PatientOverview',
      group: 'dailyOverview',
      type: 'line',
      height: 600
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Patient Footfall',
      align: 'left',
      offsetX: 110
    },
    xaxis: {
      categories: (() => { return ParsedOverviewChartData.Financial.TotalRevenue.map(e => e.x) })(),
    },
    yaxis: [
      {
        seriesName: 'Patients',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          },
        },
        title: {
          text: "Patient Count",
          style: {
            color: '#008FFB',
          }
        },
        crosshairs: {
          show: false,
        }
      }
    ],
    tooltip: {
      theme: 'dark'
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  return (
    // <div>patientsChart</div>
    <Chart options={chartOptions} series={chartSeries} type="line" height={600} />
  )
}

export default DailyPatientsChart