import React, { useState, useEffect } from 'react';
import Maincontainer from './Layout/Maincontainer';
import FilterForm from "./FilterForm";
import './App.css';
import GetApiData from "./utils/GetApiData";
// import { Table, Tag, Space } from 'antd';
import Login from './Login/Login';

const App = ({ _isAlreadyLogin = false, _isLogin = false }) => {

  const windowHost = window.location.origin;
  const apiHost = windowHost.includes('.co.in') ? windowHost : 'https://nch.his.clinivantage.co.in'
  // const apiHost = windowHost == "http://localhost:3000" ? 'https://qa.his.clinivantage.dev' : windowHost;
  // const apiHost = windowHost == "http://localhost:3000" ? 'https://nch.his.clinivantage.co.in' : windowHost;


  const _subDomain = apiHost.split('//')[1].split(".")[0];
  // const subDomain = (_subDomain == "sme" || _subDomain == "qa" || _subDomain == "demo" || _subDomain == "intdev") ? "localhost" : _subDomain
  const subDomain = 'nch'

  const [organisationList, setOrganisationList] = useState([])

  useEffect(async () => {
    let _organisationList = await GetApiData.getOrganisationList({
      subDomain,
      apiHost
    })
    if (localStorage.getItem("isLogin")) {

    }
    else {
      localStorage.setItem("isLogin", "no")
    }

    setOrganisationList(_organisationList);

  }, []

  )
  // let getOrgId = windowHost.includes('co.in') ? JSON.parse(decodeURIComponent(document.cookie.split("cookieObject=")[1].split(";")[0])).organizationId : 10
  const [zoomedIn, setZoomedIn] = useState(false)
  const [units, setUnits] = useState([]);
  let _varLog = localStorage.getItem("isLogin");
  const [isLogin, setIsLogin] = useState();
  const [organizationId, setOrganizationId] = useState()
  const [isLogout, setISLogout] = useState(false);
  const [currencyInfo, setCurrencyInfo] = useState("")


  const fnLogout = () => {
    setISLogout(true);
    setIsLogin(false);
    _isAlreadyLogin = false;
    localStorage.setItem("isLogin", "no")
  }
  const changeSelectedKey = (isLogin) => {
    setIsLogin(isLogin);
  };

  const _fngetOrgId = async (id, currency) => {
    setOrganizationId(id);
    // setCurrencyInfo(currency);
    setCurrencyInfo('INR');
    let unitsListApi = await GetApiData.getUnits({
      organizationId,
      apiHost
    })
    setUnits(unitsListApi)
  }
  useEffect(async () => {
    if (_isAlreadyLogin) {

      _fngetOrgId(organizationId)
    }
  }, _isAlreadyLogin)

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 100
    },
    {
      title: 'age',
      dataIndex: 'age',
      key: 'áge',
      fixed: 'left',
      width: 100

    },
    { title: 'Column 1', dataIndex: 'address', key: '1' },
    { title: 'Column 2', dataIndex: 'std', key: '2' },
    { title: 'Column 3', dataIndex: 'address', key: '3' },
    { title: 'Column 4', dataIndex: 'address', key: '4' },
    { title: 'Column 5', dataIndex: 'address', key: '5' },
    { title: 'Column 6', dataIndex: 'address', key: '6' },
    { title: 'Column 7', dataIndex: 'address', key: '7' },
    { title: 'Column 8', dataIndex: 'address', key: '8' },
    {
      title: 'action',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <a>action</a>

      )
    }

  ];
  // {
  //   console.log(isLogout, "iiiiis")
  // }
  return (
    <>

      {
        localStorage.getItem("isLogin") == "no" && !_isAlreadyLogin &&
        <Login
          changeSelectedKey={changeSelectedKey}

          apiHost={apiHost}
          organisationList={organisationList}
          defaultOrgName={organisationList.length == 1 ? organisationList[0].organizationName : ''}
          _fngetOrgId={_fngetOrgId}

          apiHost={apiHost}
          isLogout={isLogout}

        />

      }

      {
        localStorage.getItem("isLogin") == "yes" && !_isAlreadyLogin &&
        <div className="mainContianer">
          <Maincontainer setZoomedIn={setZoomedIn} zoomedIn={zoomedIn} fnLogout={fnLogout}>
          </Maincontainer>
          <div className="clear"></div>
          <div style={{ margin: '24px' }} className="FilterForm">
            <FilterForm setZoomedIn={setZoomedIn} zoomedIn={zoomedIn} apiHost={apiHost} units={units} organizationId={organizationId}
              _Login={true}
              _currencyInfo={currencyInfo}
            />
          </div>

        </div>
      }
    </>
  )
}

export default App;