import React from 'react'
import Chart from 'react-apexcharts'

const DailyExpensesChart = (({ apiData, currencyInfo }) => {
  const optionsExpenses = {
    chart: {
      id: 'expensesOverview',
      group: 'expensesOverview',
      type: 'line',
      stacked: false,
      height: 600,
      events: {
        mouseMove: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Expenses Overview',
      align: 'left',
      offsetX: 110
    },
    colors: ['#008FFB', '#00E396'],
    yaxis: [
      {
        seriesName: 'Expenses',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)
            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value)
          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        },
        tooltip: {
          theme: "dark"
        }
      }
    ],
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let ExpenseSeriesData = []

    apiData.forEach(d => {
      let currDateTotalExpense = 0

      d.expenses && d.expenses.length > 0 && d.expenses[0].ledgers && d.expenses[0].ledgers.forEach(ledger => {
        currDateTotalExpense += Object.values(ledger)[0]
      })

      ExpenseSeriesData.push({
        "x": d.trandate,
        "y": currDateTotalExpense
      })
    })

    return [{
      name: "Expenses",
      data: ExpenseSeriesData,
      type: 'line'
    }]
  }

  return <Chart options={optionsExpenses} series={getSeriesData()} type="line" height={600} />
})

export default DailyExpensesChart