import React from 'react'
import Chart from 'react-apexcharts'

const DailyFinancialOverviewChart = ({ apiData, currencyInfo }) => {
  console.log(currencyInfo, "hhhhhh")
  const optionsFinancial = {
    chart: {
      id: 'financialOverview',
      group: 'dailyOverview',
      type: 'line',
      stacked: false,
      height: 600,
      events: {
        mouseMove: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Financial Analysis',
      align: 'left',
      offsetX: 110
    },

    colors: ['#008FFB', '#00E396', '#F15B46'],
    yaxis: [
      {
        seriesName: 'Revenue',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)
            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value) 
          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        }
      }
    ],
    tooltip: {
      theme: "dark"
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let RevenueSeriesData = [], CollectionSeriesData = [], OutstandingSeriesData = []

    apiData.forEach(dd => {
      let totalDailyRev = 0
      let totalDailyCollection = 0
      let totalDailyOutstandings = 0

      dd.revenues.forEach(mrev => {
        if (mrev == null) return
        totalDailyRev += Object.values(mrev)[0]
      })

      if (dd.outstandings && dd.outstandings.length > 0) {
        dd.outstandings.forEach(out => {
          totalDailyOutstandings += Object.values(out)[0]
        })
      }

      dd.payments.forEach(mPayment => {
        totalDailyCollection += Object.values(mPayment)[0]
      })

      RevenueSeriesData.push({
        "x": dd.trandate,
        "y": totalDailyRev
      })

      CollectionSeriesData.push({
        "x": dd.trandate,
        "y": totalDailyCollection
      })

      OutstandingSeriesData.push({
        "x": dd.trandate,
        "y": totalDailyOutstandings
      })

    })

    return {
      FinancialSeries: [
        {
          name: "Revenue",
          data: RevenueSeriesData,
          type: 'line'
        },
        {
          name: "Collection",
          data: CollectionSeriesData,
          type: 'line'
        },
        {
          name: "Outstanding",
          data: OutstandingSeriesData,
          type: 'line'
        }
      ]
    }
  }

  return (
    <Chart options={optionsFinancial} series={getSeriesData().FinancialSeries} type="line" height={600} />
  )
}

export default DailyFinancialOverviewChart