const DummyData = {}

DummyData.Alos = [
  {
    spec: "Cardiac Angioplasty",
    los: 5,
    discharges: 163
  },
  {
    spec: "Coronary Angiography",
    los: 6,
    discharges: 145
  },
  {
    spec: "Haemorrhoid Surgery",
    los: 4,
    discharges: 82
  },
  {
    spec: "Bronchoscopy",
    los: 8,
    discharges: 73
  },
  {
    spec: "Pleural biopsy",
    los: 6,
    discharges: 67
  },
  {
    spec: "Transbronchial lung biopsy",
    los: 3,
    discharges: 59
  },
  {
    spec: "Bowel Procedure",
    los: 4,
    discharges: 54
  },
  {
    spec: "Liver Transplant",
    los: 8,
    discharges: 13
  },
]

export default DummyData