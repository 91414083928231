import React from 'react'
import Chart from 'react-apexcharts'

const PatientIpOpDonut = ({ ip, op }) => {

  const getOptions = () => {
    return {
      chart: {
        type: 'donut',
      },
      title: {
        text: 'Patients'
      },
      labels: ['IP', 'OP'],
      // labels: [`IP: ${ip}`, `OP: ${op}`],
      legend: { position: 'bottom' },
      colors: ['#800080', '#DAA520'],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      }
    }
  }

  const getSeries = () => {
    var retArray = []
    retArray.push(ip)
    retArray.push(op)
    return retArray
  }

  return < Chart options={getOptions()} series={getSeries()} type="donut" height={320} />

}

export default PatientIpOpDonut