import React, { useState, useEffect } from 'react'
import { Select, DatePicker, Button, Space } from "antd";
import { AreaChartOutlined } from '@ant-design/icons';
import moment from 'moment';
import RenderWhichReportWithData from './RenderWhichReportWithData';
import GetApiData from "./utils/GetApiData";
import "./FilterForm.css"
const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterForm = ({ zoomedIn, setZoomedIn, apiHost, units, organizationId, Login, _currencyInfo }) => {
  const [unitId, setUnitId] = useState(1)
  const [unitName, setUnitName] = useState('Nanyuki Cottage Hospitalv')
  const [pickerType, setPickerType] = useState('yesterday');
  const [unitList, setUnitList] = useState(units)
  const [showGetButton, setShowGetButton] = useState(false)
  const [startPeriod, setStartPeriod] = useState()
  const [endPeriod, setEndPeriod] = useState()
  const [renderReports, setRenderReports] = useState(false)
  const [currencyInfo, setCurrencyInfo] = useState(_currencyInfo);

  const resetP = () => { setRenderReports(false) }

  useEffect(async () => {
    let _organizationId = localStorage.getItem("organisationId"),
      organizationId = _organizationId;
    let unitsListApi = await GetApiData.getUnits({
      organizationId,
      apiHost
    })
    setUnitList(unitsListApi)
    units = unitsListApi;
  }, [])

  if (zoomedIn) {

    return (
      <>
        <Space direction="vertical" size="large">
          {showGetButton && <Button type="primary" icon={<AreaChartOutlined />} onClick={() => { setRenderReports(true) }}> Get Reports </Button>}
          {(renderReports || (pickerType === 'yesterday')) &&
            <RenderWhichReportWithData
              pickerType={pickerType}
              startPeriod={startPeriod}
              endPeriod={endPeriod}
              unitId={unitId}
              setZoomedIn={setZoomedIn}
              zoomedIn={zoomedIn}
              apiHost={apiHost}
              organizationId={organizationId}
              unitList={unitList}
              apiHost={apiHost}
              Login={Login}
              resetP={resetP}
              currencyInfo={currencyInfo}
            />}
        </Space>
      </>
    )
  }

  return (
    <>
      <div style={{}}>
        <Space direction="vertical" size="large" style={{ width: 'calc(100vw - 48px)', }} className="selectBox"  >
          {
            window.innerWidth <= 768 ?
              <Space size="large">
                <Select onChange={(val, opt) => {
                  setRenderReports(false)
                  setUnitId(val);
                  setUnitName(opt.children);
                  let unitId = val
                  GetApiData.getCurrencyInfo({
                    unitId,
                    apiHost
                  }).then((data) => {
                    setCurrencyInfo(data)
                  }).catch(e => {

                  })
                  // setselectedUnit(u.unitName)
                }}
                  placeholder="Select Unit"
                  style={{ width: '320px' }}>
                  {unitList && unitList.map(u => {
                    return <Option value={u.unitId} key={u.unitId}>{u.unitName}</Option>
                  })}
                </Select>
                <div className="clear"></div>
                <Select
                  defaultValue="yesterday"
                  onChange={(val, opt) => {
                    setPickerType(val)
                    setRenderReports(false)
                    // setselectedUnit(u.unitName)
                  }}
                  placeholder="Select Period"
                  style={{ width: '136px' }}
                  className="periodType"
                >
                  <Option value="yesterday">Yesterday</Option>
                  <Option value="lastSevenDays">Last 7 Days</Option>
                  <Option value="date">Daily</Option>
                  <Option value="month">Monthly</Option>
                  {/* <Option value="quarter">Quarterly</Option> */}
                </Select>
                <div className="clear"></div>
                {pickerType !== null && pickerType !== 'yesterday' && pickerType !== 'lastSevenDays' &&
                  <div className="rangePicker">
                    < RangePicker className="rangeType"
                      defaultPickerValue={moment(new Date())}
                      defaultValue={[moment(new Date()), moment(new Date())]}
                      picker={pickerType}
                      style={{ width: '24vw' }}
                      inputReadOnly
                      disabledDate={disabledDate}
                      onCalendarChange={(val) => {
                        setRenderReports(false)
                        const [start, end] = val
                        setStartPeriod(start)
                        setEndPeriod(end)
                        if (start && end) setShowGetButton(true)
                      }}
                    />
                  </div>

                }
                {(showGetButton || pickerType == 'lastSevenDays') && <Button type="primary" icon={<AreaChartOutlined />} onClick={() => { setRenderReports(true) }} style={{ width: "122px" }} className="getReports"> Get Reports </Button>}
              </Space>



              :
              window.innerWidth > 768 &&
              !renderReports &&
              <Space size="large">
                <Select
                  defaultValue="Nanyuki Cottage Hospital"
                  // defaultValue={`${unitList[0]['unitName']}`}
                  defaultActiveFirstOption={true}

                  onChange={(val, opt) => {
                    setUnitId(val);
                    setUnitName(opt.children);
                    let unitId = val
                    GetApiData.getCurrencyInfo({
                      unitId,
                      apiHost
                    }).then((data) => {
                      setCurrencyInfo(data)
                    }).catch(e => { })
                  }}
                  // placeholder="Select Unita"
                  style={{ width: '320px' }}
                >
                  {unitList && unitList.map(u => {
                    return <Option value={u.unitId} key={u.unitId}>{u.unitName}</Option>
                  })}
                </Select>
                <div className="clear"></div>
                <Select onChange={setPickerType}
                  defaultValue="yesterday"
                  // placeholder="Select Period"
                  style={{ width: '136px' }}
                  className="periodType"
                >
                  <Option value="yesterday">Yesterday</Option>
                  <Option value="lastSevenDays">Last 7 Days</Option>
                  <Option value="date">Daily</Option>
                  <Option value="month">Monthly</Option>
                  {/* <Option value="quarter">Quarterly</Option> */}
                </Select>
                <div className="clear"></div>
                {pickerType !== null && pickerType !== 'lastSevenDays' && pickerType !== 'yesterday' &&
                  <div className="rangePicker">
                    < RangePicker className="rangeType"
                      defaultPickerValue={moment(new Date())}
                      defaultValue={[moment(new Date()), moment(new Date())]}
                      picker={pickerType}
                      // onChange={value => console.log(value)}
                      style={{ width: '24vw' }}
                      inputReadOnly
                      disabledDate={disabledDate}
                      onCalendarChange={(val) => {
                        const [start, end] = val
                        setStartPeriod(start)
                        setEndPeriod(end)
                        if (start && end) setShowGetButton(true)
                      }}
                    />
                  </div>

                }
                {(showGetButton || pickerType == 'lastSevenDays') && <Button type="primary" icon={<AreaChartOutlined />} onClick={() => { setRenderReports(true) }} style={{ width: "122px" }} className="getReports"> Get Reports </Button>}
              </Space>
          }

          {(renderReports || pickerType === 'yesterday') && unitList && unitList.length > 0 &&
            < RenderWhichReportWithData
              pickerType={pickerType}
              startPeriod={startPeriod}
              endPeriod={endPeriod}
              unitId={unitId}
              setZoomedIn={setZoomedIn}
              apiHost={apiHost}
              organizationId={organizationId}
              unitName={unitName}
              unitList={unitList}
              currencyInfo={currencyInfo}
              Login={true}
              resetP={resetP}
            />}
        </Space>
      </div>
    </>
  )
}

export default FilterForm

const disabledDate = (current) => {
  return current && current > moment().endOf('day');
}
