import React from 'react'
import moment from 'moment'
import Chart from 'react-apexcharts'

const MonthlyCollectionsChart = ({ apiData, currencyInfo }) => {

  const optionsFinancial = {
    chart: {
      id: 'financialOverview',
      group: 'monthlyOverview',
      type: 'line',
      stacked: false,
      height: 600,
      events: {
        mouseMove: function (event, chartContext, config) {
          // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4, 1, 1],
      curve: 'smooth'
    },
    title: {
      text: 'Collections',
      align: 'left',
      offsetX: 110
    },

    colors: ['#008FFB', '#00E396', '#F15B46'],
    yaxis: [
      {
        seriesName: 'Revenue',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#141414',
          },
          formatter: (value) => {
            return currencyInfo + new Intl.NumberFormat('en-IN').format(value)
            // new Intl.NumberFormat('en-IN', { style: 'currency', currency: currencyInfo }).format(value) 
          },
          minWidth: 80
        },
        title: {
          text: "Amount",
          style: {
            color: '#141414',
          }
        },
        tooltip: {
          theme: "dark"
        }
      }
    ],
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    }
  }

  const getSeriesData = () => {
    let CollectionSeriesData = []

    apiData.forEach(md => {
      let totalMonthlyCollection = 0

      md.payments.forEach(mPayment => {
        totalMonthlyCollection += Object.values(mPayment)[0]
      })

      CollectionSeriesData.push({
        "x": moment(md.trandate).format("MMM-YYYY"),
        "y": totalMonthlyCollection
      })

    })

    return {
      FinancialSeries: [
        {
          name: "Collection",
          data: CollectionSeriesData,
          type: 'line'
        }
      ]
    }
  }

  return (
    <>
      <Chart options={optionsFinancial} series={getSeriesData().FinancialSeries} type="line" height={600} />
      <br />
    </>
  )
}

export default MonthlyCollectionsChart