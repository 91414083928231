import React from 'react'
import Chart from 'react-apexcharts'

const PatientTypeRadial = () => {
  const getSeries = () => {
    return [60, 40, 56, 87]
  }

  const getOptions = () => {
    return {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      title: {
        text: 'Room Occupancy by %'
      },
      legend: { show: true, position: 'bottom' },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '16px',
            },
            total: {
              show: true,
              label: 'Total',
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return '68%'
              }
            }
          }
        }
      },
      labels: ['ICU', 'Suite', 'VIP', 'General'],
    }
  }

  return <Chart options={getOptions()} series={getSeries()} type="radialBar" height={320} />

}

export default PatientTypeRadial