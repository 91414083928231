import React, { useEffect, useState } from 'react';
import { Layout, Typography, Space } from 'antd';

import { LogoutOutlined } from '@ant-design/icons';
const { Header, Content } = Layout
const { Title } = Typography;

const Maincontainer = ({ children, zoomedIn, setZoomedIn, fnLogout }) => {

  if (zoomedIn) {
    return (
      <div style={{ marginLeft: '12px', marginRight: '12px' }}>
        {children}
      </div>
    )
  }


  return (< Layout className="layout" >
    <Header style={{ backgroundColor: "#326ea0", zIndex: 1, width: '100%' }} className="headerTop">
      {/* <NavBar menu={Menu} /> */}

      <div className="Logo" />

      <div className="fleft headerTitle">
        <Title level={4} style={{ color: "#c1c1c1", marginTop: "20px", fontSize: '14px' }}>  BI DashBoard</Title>
      </div>

      <div className="logoutIcon">
        <LogoutOutlined style={{ width: '50px' }} onClick={fnLogout} />
      </div>
    </Header>

    <Content >
      {/* <Content style={{ marginTop: '72px', padding: '24px' }}> */}
      {children}
    </Content>
  </Layout >)
}

export default Maincontainer