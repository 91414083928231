import React from 'react';
// import { Layout } from "antd";
import { Tabs, Button, Typography, Menu, Dropdown, DatePicker, Select, Layout } from 'antd';
import {
    RiseOutlined, DollarOutlined, FundProjectionScreenOutlined, RedoOutlined,
    CreditCardOutlined, ReconciliationOutlined, TeamOutlined, DollarCircleOutlined,
    ExclamationCircleOutlined, DownOutlined, TableOutlined, BarChartOutlined,
    MinusCircleOutlined, ExperimentOutlined, VideoCameraOutlined
} from '@ant-design/icons';
// import './SideDrawer.css';
// import DailyFinancialOverviewTable from '../../DailyView/DailyFinancialOverviewTable';
// import DailyFinancialOverviewChart from '../../DailyView/DailyFinancialOverviewChart';
const SideBar = ({ menu }) => {
    const menu1 = (
        <Menu>
            <Menu.Item key="0" icon={<DollarOutlined />}>
                Overview
                    <Button type="link" icon={<TableOutlined />} onClick={() =>

                    this.setState({
                        contentKey: 'revenueOverviewTable', drawerClasses: 'side_drawer',
                        // renderedComponent: <DailyFinancialOverviewTable apiData={this.state.apiData} currencyInfo={this.state.currencyInfo} />
                    })
                } >
                    Table
                    </Button>&nbsp;
                    <Button type="link" icon={<BarChartOutlined />} onClick={() =>

                    this.setState({
                        contentKey: 'revenueOverviewChart', drawerClasses: 'side_drawer',
                        // renderedComponent: <DailyFinancialOverviewChart apiData={this.state.apiData} currencyInfo={this.state.currencyInfo} />
                    }

                    )
                }>
                    Chart
                    </Button>
            </Menu.Item>
        </Menu>)
    return (
        <Layout.Sider
            className="sidebar"
            breakpoint={"lg"}
            theme="light"
            collapsedWidth={0}
            trigger={null}
        >


            <ul>
                <li>Overview</li>
                <li>
                    <Dropdown overlay={menu1}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            Financial <DownOutlined />
                        </a>
                    </Dropdown>,
                         </li>


            </ul>

        </Layout.Sider>
    );
};
export default SideBar;