import React, { useState, useEffect } from 'react'
import moment from 'moment'
import DailyDashboard from './DailyView/DailyDashboard'
import MonthlyDashboard from './MonthlyView/MonthlyDashboard'
import LoadingSvg from './Layout/LoadingSvg'
// import SampleApiData from './data/SampleApiData'
import getXformedDailyDataString from './utils/XformedDailyData'
import GetApiData from './utils/GetApiData'
import YdayDash from "./Pocs/YdayDash";

const RenderWhichReportWithData = ({ pickerType, startPeriod, endPeriod, unitId, setZoomedIn, zoomedIn, apiHost, organizationId,
  unitName, unitList, resetP, currencyInfo }) => {
  console.log("RenderWhichReportWithData START!!!")
  const reset = () => { resetP() }
  const [apiDataReady, setApiDataReady] = useState(false)
  // const [xformedData, setXformedData] = useState(getXformedDailyDataString())
  const [apiData, setApiData] = useState()
  const [xformedData, setXformedData] = useState();
  const changeDashBoard = (dName) => { pickerType = dName }
  useEffect(() => {
    //this useeffect is for calling with API call using Axios
    // console.log('useEffect with axios api', apiHost, "::", GetApiData)

    if (pickerType === 'yesterday' || pickerType === 'date' || pickerType === 'lastSevenDays') {
      let fromDate, toDate;
      if (pickerType === 'yesterday') {
        fromDate = moment(new Date()).subtract(1, 'days');
        toDate = moment(new Date()).subtract(1, 'days');
      }
      else if (pickerType === 'lastSevenDays') {
        fromDate = moment(new Date()).subtract(8, 'days');
        toDate = moment(new Date()).subtract(1, 'days');
      } else {
        fromDate = new Date(startPeriod)
        toDate = new Date(endPeriod)
      }
      organizationId = localStorage.getItem("organizationId");
      console.log(organizationId, unitId, fromDate, toDate, apiHost, "ppppppppp")
      GetApiData.daily({ organizationId, unitId, fromDate, toDate, apiHost })
        .then(data => {
          // console.log(data)
          setXformedData(getXformedDailyDataString(data))
          setApiData(data)
          setApiDataReady(true)
        }).catch(err => {
          console.log("eeeeeeeee", err)
        })
    } else if (pickerType === 'month') {
      let startMonth = new Date(startPeriod), endMonth = new Date(endPeriod)
      GetApiData.monthly({ organizationId, unitId, startMonth, endMonth, apiHost })
        .then(data => {
          // console.log(data)
          // setXformedData(getXformedDailyDataString(data))
          setApiData(data)
          setApiDataReady(true)
        })
    }
  }, [setApiDataReady])


  return (
    <>
      {!apiDataReady && <p><LoadingSvg /></p>}
      {
        apiData && (pickerType === 'yesterday') &&
        <YdayDash
          data={xformedData}
          apiData={apiData}
          startPeriod={startPeriod}
          endPeriod={endPeriod}
          firstunitId={unitId}
          setZoomedIn={setZoomedIn}
          zoomedIn={zoomedIn}
          unitName={unitName}
          unitList={unitList}
          currencyInfo={currencyInfo}
          apiHost={apiHost}
          organizationId={organizationId}
          pickerType={pickerType}
          Login={true}
          changeDashBoard={changeDashBoard} reset={reset}
        />
      }
      {
        apiDataReady && (pickerType === 'date' || pickerType == 'lastSevenDays') &&
        <DailyDashboard
          data={xformedData}
          apiData={apiData} startPeriod={startPeriod} endPeriod={endPeriod} firstunitId={unitId} setZoomedIn={setZoomedIn} zoomedIn={zoomedIn}
          unitName={unitName} unitList={unitList} currencyInfo={currencyInfo} apiHost={apiHost} organizationId={organizationId} pickerType={pickerType} Login={true}
          changeDashBoard={changeDashBoard} reset={reset} />
      }
      {apiDataReady && pickerType === 'month' &&
        <MonthlyDashboard apiData={apiData}
          startPeriod={startPeriod}
          endPeriod={endPeriod} unitId={unitId} setZoomedIn={setZoomedIn} zoomedIn={zoomedIn} unitName={unitName}
          unitList={unitList} currencyInfo={currencyInfo} apiHost={apiHost} organizationId={organizationId}
          Login={true} changeDashBoard={changeDashBoard} reset={reset} />}
    </>
  )
}

export default RenderWhichReportWithData;
